import { PwaInfo1, PwaInfo2 } from "../assets/images"

const SafariModal = ({ setShowSafariModal }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[2000] flex items-center justify-center px-4">
            <div className="bg-cardBackground  rounded-xl p-6 w-full max-w-[26em] relative">
                <div className="space-y-6">
                    <h3 className="text-lg text-text font-medium text-center">To install PadhAI on iOS</h3>
                    <div className="flex items-start space-x-4">
                        <div className="flex-shrink-0 w-7 h-7 rounded-full bg-border flex items-center justify-center">
                            <span className="text-text">1</span>
                        </div>
                        <div className="flex-1">
                            <p className="text-text">Tap on Share button</p>
                            <div className="mt-2">
                                <img src={PwaInfo1} alt="Share button location" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4">
                        <div className="flex-shrink-0 w-7 h-7 rounded-full bg-border flex items-center justify-center">
                            <span className="text-text">2</span>
                        </div>
                        <div className="flex-1">
                            <p className="text-text">Scroll down and tap on 'Add to Home screen'</p>
                            <div className="mt-2">
                                <img src={PwaInfo2} alt="Add to Home screen option" className="w-full" />
                            </div>
                        </div>
                    </div>
                    <button onClick={() => setShowSafariModal(false)} className="w-full py-3 bg-border rounded-full text-text font-bold text-800">
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SafariModal