export const isWebViewApp = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /Mobile|Android|iPhone|iPad|iPod/.test(userAgent) && /wv|WebView|FBAN|FBAV/.test(userAgent);
};

export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const throttle = (func, delay) => {
  let lastCallTime = 0;
  return (...args) => {
    const now = new Date().getTime();
    if (now - lastCallTime < delay) return;
    lastCallTime = now;
    func(...args);
  };
};
