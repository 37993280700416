import moment from 'moment';
import { NEWS_SOURCE } from '../constants/newsData';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getNewsPaperParamForApi = (newsSource) => {
  switch (newsSource) {
    case NEWS_SOURCE.THE_HINDU:
      return 'The%20Hindu';
    case NEWS_SOURCE.THE_INDIAN_EXPRESS:
      return 'The%20Indian%20Express';
    case NEWS_SOURCE.ALL:
      return 'All%20Newspapers';
    default:
      return 'The%20Hindu';
  }
};

const fetchNews = async (apiUrl) => {
  try {
    const response = await axios.get(apiUrl);
    return response;
  } catch (error) {
    console.error('API error:', error);
    throw error;
  }
};

export const getNews = async ({ userId, selectedNews, date, readingTime }) => {
  const formattedDate = date || moment().format('YYYY-MM-DD');

  let apiUrl = `${BASE_URL}/api/news-summary/?news_paper=${getNewsPaperParamForApi(selectedNews)}&date=${formattedDate}&user=${userId}&timezone=${
    Intl.DateTimeFormat().resolvedOptions().timeZone
  }`;

  if (readingTime) {
    apiUrl += `&reading_time=${readingTime}`;
  }

  let response = await fetchNews(apiUrl);

  if (response.status !== 200) {
    throw new Error('Failed to fetch news');
  }

  if (response.status === 200) {
    if (response.data.length === 0 && date === moment().format('YYYY-MM-DD')) {
      response = await fetchNews(apiUrl);
    }
  }

  return response.data;
};

export const getNewsById = async (id, userId) => {
  const apiUrl = `${BASE_URL}/api/news-summary/${id}/?user=${userId}`;
  const response = await fetchNews(apiUrl);
  return response.data;
};
