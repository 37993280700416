import React, { createContext, useState, useContext } from "react";
import RatingModal from "../molecules/RatingModal";

const ModalContext = createContext();
export const AppRatingProvider = ({ children }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [source, setSource] = useState('')
    const showRatingModal = (source) => {
        setModalVisible(true);
        setSource(source)
    };
    const hideModal = () => {
        setModalVisible(false);
        setSource('')

    };
    return (
        <ModalContext.Provider value={{ showRatingModal, hideModal }}>
            {children}
            <RatingModal
                visible={modalVisible}
                onClose={hideModal}
                source={source}
            />
        </ModalContext.Provider>
    );
};
export const useModal = () => useContext(ModalContext);