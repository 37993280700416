import { ReactComponent as HistoryBook } from '../assets/images/HistoryBook.svg';
import { ReactComponent as GeographyBook } from '../assets/images/GeographyBook.svg';
import { ReactComponent as PolityBook } from '../assets/images/Polity.svg';
import { ReactComponent as CurrentAffairs } from '../assets/images/CurrentAffairs.svg';
import { ReactComponent as EconomyBook } from '../assets/images/Economy.svg';
import { ReactComponent as EnvironmentBook } from '../assets/images/environment_book.svg';
import { ReactComponent as ScienceBook } from '../assets/images/science_book.svg';
import { ReactComponent as AllSubjects } from '../assets/images/all_subject_book.svg';
import { ReactComponent as CSATBook } from '../assets/images/csat_book.svg';

export const iconProps = {
  stroke: 1.5,
  color: 'currentColor',
  opacity: 1,
  size: 18,
};

// Define the icons object
export const icons = {
  all_subjects: <AllSubjects {...iconProps} />,
  csat: <CSATBook {...iconProps} />,
  history: <HistoryBook {...iconProps} />,
  geography: <GeographyBook {...iconProps} />,
  polity: <PolityBook {...iconProps} />,
  current_affairs: <CurrentAffairs {...iconProps} />,
  economy: <EconomyBook {...iconProps} />,
  science: <ScienceBook {...iconProps} />,
  environment: <EnvironmentBook {...iconProps} />,
};

export const HeartInactive = ({ color }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2656 22.3625C11.9556 22.3625 11.6556 22.3225 11.4056 22.2325C7.58563 20.9225 1.51562 16.2725 1.51562 9.4025C1.51562 5.9025 4.34562 3.0625 7.82562 3.0625C9.51562 3.0625 11.0956 3.7225 12.2656 4.9025C13.4356 3.7225 15.0156 3.0625 16.7056 3.0625C20.1856 3.0625 23.0156 5.9125 23.0156 9.4025C23.0156 16.2825 16.9456 20.9225 13.1256 22.2325C12.8756 22.3225 12.5756 22.3625 12.2656 22.3625ZM7.82562 4.5625C5.17562 4.5625 3.01562 6.7325 3.01562 9.4025C3.01562 16.2325 9.58562 20.0325 11.8956 20.8225C12.0756 20.8825 12.4656 20.8825 12.6456 20.8225C14.9456 20.0325 21.5256 16.2425 21.5256 9.4025C21.5256 6.7325 19.3656 4.5625 16.7156 4.5625C15.1956 4.5625 13.7856 5.2725 12.8756 6.5025C12.5956 6.8825 11.9556 6.8825 11.6756 6.5025C10.7456 5.2625 9.34562 4.5625 7.82562 4.5625Z"
        fill={color}
      />
    </svg>
  );
};

export const HeartActive = ({ color }) => {
  return (
    <svg width="30" height="30" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7066 3.7373C14.8966 3.7373 13.2766 4.6173 12.2666 5.9673C11.2566 4.6173 9.6366 3.7373 7.8266 3.7373C4.7566 3.7373 2.2666 6.2373 2.2666 9.3273C2.2666 10.5173 2.4566 11.6173 2.7866 12.6373C4.3666 17.6373 9.2366 20.6273 11.6466 21.4473C11.9866 21.5673 12.5466 21.5673 12.8866 21.4473C15.2966 20.6273 20.1666 17.6373 21.7466 12.6373C22.0766 11.6173 22.2666 10.5173 22.2666 9.3273C22.2666 6.2373 19.7766 3.7373 16.7066 3.7373Z"
        fill={color}
      />
    </svg>
  );
};
