import React from "react";
import Lottie from "react-lottie";
import * as successAnimation from '../assets/json/report_success.json';
import { IconX } from "@tabler/icons-react";
import { useTheme } from "../context/themeProvider";

const ReportModaContent = ({
  isOpen,
  isMobile,
  closeModal,
  isReportSubmited,
  reportData,
  setReportData,
  details,
  onChange,
  handleReportSubmit,
}) => {
  const { colorScheme } = useTheme();
  const textareaRef = React.useRef(null);

  React.useEffect(() => {
    if (reportData === "Other" && textareaRef.current) {
      textareaRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [reportData]);

  if (!isOpen) return null;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const buttonBg = reportData !== "" ? colorScheme === "dark" ? "#fff" : "#070519" : colorScheme === "light" ? "#F3F3F3" : "#464646"
  const buttonTxt = reportData !== "" ? colorScheme === "dark" ? "#070519" : "#fff" : colorScheme === "light" ? "#B9B9B9" : "#5D5D5D"

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-[9999]" onClick={closeModal}>
      <div
        className={`relative bg-cardBackground p-6 rounded-t-xl shadow-lg w-full text-text ${isMobile ? "max-w-[28rem]" : "max-w-[22vw]"
          } text-center`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-center mb-8">
          <span
            className="w-[89px] h-[6px] bg-gray-300 rounded-full cursor-pointer"
            onClick={closeModal}
          ></span>
        </div>

        <div className="absolute top-4 right-5">
          <IconX className="cursor-pointer text-text" onClick={closeModal} />
        </div>

        {!isReportSubmited ? (
          <>
            <h3 className="text-lg font-semibold text-left font-poppins mb-4">Report Question</h3>
            <div className="space-y-3 custom-scrollbar" style={{ maxHeight: "calc(100vh - 281px)" }}>
              <div>
                {[
                  "Wrong category",
                  "Answer is incorrect",
                  "Incomplete question",
                  "Mistake in question",
                  "Mistake in options",
                  "Poorly formatted question",
                  "Poorly formatted options",
                  "Other",
                ].map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center space-x-2 py-4 cursor-pointer"
                    onClick={() => setReportData(item)}
                  >
                    <div
                      className={`w-5 h-5 rounded-full border-2 flex justify-center items-center ${reportData === item ? "border-blue-600" : "border-gray-300"
                        }`}
                    >
                      {reportData === item && (
                        <div className="w-3 h-3 bg-blue-600 rounded-full"></div>
                      )}
                    </div>
                    <span className="font-poppins text-md">{item}</span>
                  </div>
                ))}
              </div>

              {reportData === "Other" && (
                <>
                  <p className="text-left mb-2 font-poppins text-sm">Other reason*</p>
                  <textarea
                    ref={textareaRef}
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    className="w-full border border-gray-300 rounded-lg p-2 text-sm bg-cardBackground focus:outline-none focus:ring-2 focus:ring-blue-600 font-poppins text-md"
                    onChange={onChange}
                  />
                </>
              )}
            </div>

            <div className="mt-6">
              <button
                onClick={handleReportSubmit}
                disabled={!reportData}
                className={`w-full rounded-full p-4 font-medium font-poppins`}
                style={{ backgroundColor: buttonBg, color: buttonTxt }}
              >
                Submit Feedback
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="text-xl font-semibold mb-2 font-poppins">Report Submitted</div>
            <div className="text-md mb-6 font-poppins">Thank you! Your report has been submitted.</div>
            <Lottie options={defaultOptions} height={200} width={200} />
          </>
        )}
      </div>
    </div>
  );
};

export default ReportModaContent;