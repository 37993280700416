import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconExclamationCircle, IconEyeOff, IconEye, IconCheck, IconX } from '@tabler/icons-react';
import { Text, Badge} from '@mantine/core';
import { useTheme } from '../../context/themeProvider';
import * as successAnimation from '../../assets/json/report_success.json';
import { ArrowLeftDarkIcon, ArrowLeftFilled, ArrowRightDarkIcon, ArrowRightFilled, NoDataImage } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import dayjs from 'dayjs';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useNavigate } from 'react-router-dom';
import ReportModaContent from '../../molecules/ReportQuestion';
import { HeartActive, HeartInactive } from '../../constants/iconData';
import useQuestionsStore from '../../stores/todayquestionsStore';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-[70vh]">
    <div className="relative">
      <div className="w-12 h-12 rounded-full"></div>
      <div className="h-12 w-12  border-t-2 border-b-2 border-[#6374FA] rounded-full animate-spin absolute top-0 left-0"></div>
    </div>
  </div>
);

const TodaysQuestions = () => {
  const { colorScheme } = useTheme();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [reportData, setReportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const containerRef = useRef(null);
  const questionNavRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [activeTab, setActiveTab] = useState('mcqs');
  const [showAnswer, setShowAnswer] = useState(false);
  const navigate = useNavigate();
  
  const { 
    isLoading, 
    mcqQuestions, 
    mainsQuestions, 
    prelimsQuestions, 
    fetchQuestions,
    savePosition,
    getPosition
  } = useQuestionsStore();
  
  const [details, setDetails] = useState('');
  const [favoritesMap, setFavoritesMap] = useState({});
  const apiCallRef = useRef(false);

  const memoizedQuestions = useMemo(() => {
    switch (activeTab) {
      case 'mcqs':
        return mcqQuestions;
      case 'mains':
        return mainsQuestions;
      case 'prelims':
        return prelimsQuestions;
      default:
        return [];
    }
  }, [activeTab, mcqQuestions, mainsQuestions, prelimsQuestions]);

  useEffect(() => {
    if (selectedDate && userData?.user) {
      // Only fetch if we have valid parameters
      fetchQuestions(selectedNews, selectedDate, userData);
    }
  }, [selectedNews, selectedDate, userData, fetchQuestions]);

  useEffect(() => {
    setQuestionStates(memoizedQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
    setCurrentQuestionIndex(0);
  }, [memoizedQuestions]);

  useEffect(() => {
    if (questionNavRef.current) {
      const activeQuestion = questionNavRef.current.querySelector(`[data-index="${currentQuestionIndex}"]`);
      if (activeQuestion) {
        activeQuestion.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    const savedPosition = getPosition(activeTab);
    setCurrentQuestionIndex(savedPosition);
  }, [activeTab, getPosition]);

  useEffect(() => {
    savePosition(activeTab, currentQuestionIndex);
  }, [currentQuestionIndex, activeTab, savePosition]);

  const currentQuestion = memoizedQuestions[currentQuestionIndex];

  const dictMyCategoryName = (category) => {
    const categories = {
      economy: 'economy',
      history: 'history',
      geography: 'geography',
      polity: 'polity',
      science: 'science',
      environment: 'environment',
      csat: 'csat',
    };

    const categoryLower = category?.toLowerCase();

    return categories[categoryLower] || 'current_affairs';
  };

  const handleOptionChange = (index) => {
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = currentQuestion.options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const discussWithAi = (item, checkedStat) => {
    console.log(item, checkedStat);
    const optionKey = ['a', 'b', 'c', 'd'];
    let tutorConnectParam = {
      assoc_news: currentQuestion.news_summary_id,
      flowType: 'postGame',
      source: 'news_feed',
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: item.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: item.options[parseInt(checkedStat)],
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: [
          {
            a: item.options[0],
          },
          {
            b: item.options[1],
          },
          {
            c: item.options[2],
          },
          {
            d: item.options[3],
          },
        ],
        category: dictMyCategoryName(item.category),
        question: item.question,
        question_id: item.id,
        user_answer: item.options[checkedStat],
        user_option: optionKey[checkedStat],
        correct_answer: optionKey[item.answer],
        options: item.options,
      },
      category: dictMyCategoryName(item.category),
      preSelectAnswer: item.options[parseInt(checkedStat)],
    };
    return tutorConnectParam;
  };

  const handleSubmit = () => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });
  };

  const handleNextQuestion = () => {
    setShowAnswer(false);
    setTimeout(() => {
      const newIndex = currentQuestionIndex < memoizedQuestions.length - 1 ? currentQuestionIndex + 1 : currentQuestionIndex;
      setCurrentQuestionIndex(newIndex);
      savePosition(activeTab, newIndex);
    }, 300);
  };

  const handlePreviousQuestion = () => {
    setTimeout(() => {
      const newIndex = currentQuestionIndex > 0 ? currentQuestionIndex - 1 : currentQuestionIndex;
      setCurrentQuestionIndex(newIndex);
      savePosition(activeTab, newIndex);
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe || isRightSwipe) {
      if (isLeftSwipe) {
        handleNextQuestion();
      } else if (isRightSwipe) {
        handlePreviousQuestion();
      }
    }
  };

  const handleDiscussWithAi = () => {
    const aiTutorParams = discussWithAi(currentQuestion, questionStates[currentQuestionIndex].answerIndex);
    navigate(`/chat/mcq/`, { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'news_mcq' });
    sendPostHogEvent('discuss_with_ai', { source: 'news_mcq' });
  };

  const handleReport = (questionId) => {
    setIsOpen(true);
    setSelectedQuestionId(questionId);
    setReportSubmit(false);
    setReportData('');

    document.querySelector('.navbar-container')?.style.setProperty('z-index', '0');
    document.querySelector('.header-container')?.style.setProperty('z-index', '0');
  };

  const handleReportSubmit = async (details) => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: reportData,
      reason: '',
    };

    if (reportData === 'Other') {
      postPayload.reason = details;
    }

    MixpanelEvent('submit_feedback', {
      category: currentQuestion?.category || '',
      source: 'news_today_questions',
      selection: reportData,
      question_id: selectedQuestion
    });

    sendPostHogEvent('submit_feedback', {
      category: currentQuestion?.category || '',
      source: 'news_today_questions',
      selection: reportData,
      question_id: selectedQuestion
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();

      if (res.status === 400) {
        MixpanelEvent('report_submitted', {
          content_id: selectedQuestion,
          error: json,
          source: 'news_today_questions'
        });
        sendPostHogEvent('report_submitted', {
          content_id: selectedQuestion,
          error: json,
          source: 'news_today_questions'
        });
      } else {
        MixpanelEvent('report_submitted', {
          content_id: selectedQuestion,
          source: 'news_today_questions'
        });
        sendPostHogEvent('report_submitted', {
          content_id: selectedQuestion,
          source: 'news_today_questions'
        });
      }

      setTimeout(() => {
        handleCloseModal()
      }, 3000);
    } catch (error) {
      console.error('Error submitting report:', error);
      MixpanelEvent('report_submission_error', {
        error: error.message,
        source: 'news_today_questions'
      });
      sendPostHogEvent('report_submission_error', {
        error: error.message,
        source: 'news_today_questions'
      });
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    document.querySelector('.navbar-container')?.style.setProperty('z-index', '999');
    document.querySelector('.header-container')?.style.setProperty('z-index', '999');
  };

  const toggleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const formatQuestionText = (text) => {
    return text.split('\n').map((line, index) => (
      <p key={index} className="mb-1">{line}</p>
    ));
  };

  const setRemoveFav = async (param) => {
    if (apiCallRef.current) {
      return;
    }
    apiCallRef.current = true;
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/favourite-questions/`, {
        method: param,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          user: userId,
          question_id: currentQuestion.id,
        }),
      });
      const json = await res.json();
      apiCallRef.current = false;
      
      setFavoritesMap(prev => ({
        ...prev,
        [currentQuestion.id]: param === 'POST'
      }));
    } catch (error) {
      apiCallRef.current = false;
    }
  };

  return (
    <div className={`font-poppins text-gray-800 max-w-600 mx-auto min-h-dvh h-full flex flex-col relative pb-20`}>
      <div className={`p-4`}>
        {memoizedQuestions.length > 0 && (
          <div className="flex justify-between items-center">
            <img
              src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled}
              className={`cursor-pointer bg-cardBackground rounded-lg border border-[#D1D1D1] p-1.5 ${currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
              onClick={handlePreviousQuestion}
              alt="Arrow Left"
            />
            <div className="flex items-center gap-2 overflow-x-auto scrollbar-hidden flex-1 mx-2" ref={questionNavRef}>
              {memoizedQuestions.map((_, index) => (
                <div
                  key={index}
                  data-index={index}
                  className={`min-w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer mx-1.5 flex-shrink-0 ${index === currentQuestionIndex
                    ? `${colorScheme === 'dark' ? 'bg-[#EBF3FF] text-black' : 'bg-[rgb(219,219,219)] text-[#6374FA]'}`
                    : 'bg-transparent text-text'
                    }`}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  Q{index + 1}
                </div>
              ))}
            </div>
            <img
              src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled}
              className={`cursor-pointer bg-cardBackground rounded-lg border border-[#D1D1D1] p-1.5  ${currentQuestionIndex === memoizedQuestions.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              onClick={handleNextQuestion}
              alt="Arrow Right"
            />
          </div>
        )}
      </div>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {memoizedQuestions.length === 0 ? (
            <div className="text-center p-5 h-60 flex flex-col justify-center items-center text-gray-600">
              <img src={NoDataImage} alt="no data" className="mb-5" />
              <div className="font-semibold mb-2">No Related Questions Found</div>
              <div className="text-gray-500">No questions available today. Please check back later.</div>
            </div>
          ) : (
            <div
              className={`relative border bg-cardBackground rounded-xl border-border m-4 h-[calc(100dvh-280px)]  overflow-hidden`}
              ref={containerRef}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <div className="w-full h-full">
                <div
                  className={`p-4 h-[88%] pb-8 overflow-y-auto`}
                >
                  <div className="flex justify-between items-center mb-3">
                    <Badge color="gray">{activeTab.toUpperCase()}</Badge>
                    <div
                      className="absolute top-2 right-2 h-6 w-6 cursor-pointer"
                      onClick={() => {
                        if (favoritesMap[currentQuestion.id]) {
                          setRemoveFav("DELETE")
                        } else {
                          setRemoveFav("POST")
                        }
                      }}
                    >
                      {favoritesMap[currentQuestion.id] ? (
                        <HeartActive color={'#D95353'} />
                      ) : colorScheme === 'dark' ? (
                        <HeartInactive color={'#B9B9B9'} />
                      ) : (
                        <HeartInactive color={'#747474'} />
                      )}
                    </div>
                  </div>

                  <div className="text-base text-text font-medium mb-5">
                    {formatQuestionText(currentQuestion.question)}
                  </div>

                  {(activeTab === 'mcqs' || activeTab === 'prelims') && (
                    <div className="flex flex-col text-text gap-3">
                      {currentQuestion.options.map((option, index) => {
                        const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
                        const isCorrectAnswer = index === currentQuestion.answer;
                        const isSelectedAnswer = index === questionStates[currentQuestionIndex]?.answerIndex;

                        return (
                          <div
                            key={index}
                            onClick={() => handleOptionChange(index)}
                            className={`
                            rounded-lg p-4 cursor-pointer border flex items-center gap-1.5 
                            ${isSubmitted
                                ? isCorrectAnswer
                                  ? 'bg-correctOptionBackground border-correctOptionBackground'
                                  : isSelectedAnswer
                                    ? 'bg-wrongOptionBackground border-wrongOptionBackground'
                                    : 'bg-cardBackground border-border'
                                : isSelectedAnswer
                                  ? 'bg-selectedOptionBackground border-selectedOptionBackground'
                                  : 'bg-cardBackground border-border'
                              }
                          `}
                          >
                            <div className="flex items-center">
                              <div
                                className={`w-6 h-6 rounded-full flex justify-center items-center mr-2.5 transition-all duration-300 ${questionStates[currentQuestionIndex]?.isSubmitted
                                  ? index === currentQuestion.answer
                                    ? 'bg-[#34d399]'
                                    : index === questionStates[currentQuestionIndex].answerIndex
                                      ? 'bg-[#ff9999]'
                                      : 'bg-transparent'
                                  : 'bg-transparent'
                                  } ${questionStates[currentQuestionIndex]?.isSubmitted &&
                                    (index === currentQuestion.answer || index === questionStates[currentQuestionIndex].answerIndex)
                                    ? 'border-none'
                                    : 'border-2 border-border'
                                  }`}
                              >
                                {questionStates[currentQuestionIndex]?.answerIndex === index && !questionStates[currentQuestionIndex]?.isSubmitted && (
                                  <div className="w-[14.5px] h-[14.5px] bg-[#6374FA] rounded-full"></div>
                                )}
                                {questionStates[currentQuestionIndex]?.isSubmitted &&
                                  (index === currentQuestion.answer ? (
                                    <IconCheck className="w-3.5 h-3.5 text-white" />
                                  ) : index === questionStates[currentQuestionIndex].answerIndex ? (
                                    <IconX className="w-3.5 h-3.5 text-white" />
                                  ) : null)}
                              </div>
                              <div className="mr-[8px] text-[#6374FA]">{String.fromCharCode(65 + index)}.</div>
                              <div className="text-[15px] font-medium flex-1 text-text">{option}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {activeTab === 'mains' && showAnswer && (
                    <div className="mt-5 p-4 bg-gray-100 rounded-lg">
                      <Text className="text-sm leading-relaxed">{formatQuestionText(currentQuestion.solution)}</Text>
                    </div>
                  )}
                  <div className="flex justify-between p-4 shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)] w-full bg-cardBackground border-t border-border absolute bottom-0 left-0 right-0 z-50 box-border">
                    <button
                      className="text-[#D95353] flex items-center justify-center gap-1 py-2 px-4 rounded-full font-medium focus:outline-none"
                      onClick={() => handleReport(currentQuestion.id)}
                    >
                      <IconExclamationCircle size={16} />
                      Report
                    </button>
                    {activeTab === 'mains' ? (
                      <button
                        onClick={toggleShowAnswer}
                        className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-full"
                      >
                        {showAnswer ? <IconEyeOff className="mr-2" /> : <IconEye className="mr-2" />}
                        {showAnswer ? 'Hide Answer' : 'Show Answer'}
                      </button>
                    ) : (activeTab === 'mcqs' || activeTab === 'prelims') && !questionStates[currentQuestionIndex]?.isSubmitted ? (
                      <button
                        onClick={handleSubmit}
                        disabled={questionStates[currentQuestionIndex]?.answerIndex === null}
                        className={`${questionStates[currentQuestionIndex]?.selectedOption
                          ? (colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white')
                          : 'bg-transparent text-[#B9B9B9] cursor-not-allowed border border-[#D1D1D1] hover:bg-transparent transition-all duration-300 opacity-50'
                          } rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium`}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        onClick={handleDiscussWithAi}
                        className="px-4 py-2 border border-text text-text  rounded-full"
                      >
                        Discuss with AI
                      </button>
                    )}
                  </div>
                </div>


              </div>
            </div>
          )}

          <ReportModaContent
            isOpen={isOpen}
            closeModal={handleCloseModal}
            reportData={reportData}
            setReportData={setReportData}
            details={details}
            onChange={(e) => setDetails(e.currentTarget.value)}
            handleReportSubmit={handleReportSubmit}
            isReportSubmited={isReportSubmited}
            isMobile={isMobile}
          />
        </>
      )}
    </div>
  );
}

export default TodaysQuestions;
