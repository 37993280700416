import React from 'react';
import { useTheme } from '../../context/themeProvider';
import { FireSteak, FireBackIcon, FireZero, FireBackIconDark } from '../../assets/images';

const StreakProgress = ({ streak, setModalVisible }) => {
  const maxStreak = 30;
  const progress = (streak / maxStreak) * 100;
  const { colors,colorScheme } = useTheme();
  const thresholds = [10, 20, 30];

  const isStreakZero = streak === 0;

  return (
    <div
      className={`flex items-center rounded-lg shadow-md border w-full overflow-hidden bg-cardBackground text-text border-border`}
    >
      <div className="flex items-center flex-1">
        <div className="relative w-[70px] h-[80px]">
          {isStreakZero ? (
            <img src={FireZero} alt="" className="w-[70px] h-[80px] p-[10px] mr-[15px]" />
          ) : (
            <>
              <img src={colorScheme === 'dark' ? FireBackIconDark : FireBackIcon} alt="" className="absolute top-0 left-0 w-full h-full object-cover" />
              <img
                src={FireSteak}
                alt=""
                className="absolute top-1/2 left-1/2 w-[70%] h-[70%] transform -translate-x-1/2 -translate-y-1/2"
              />
              <span className="absolute top-[55%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-bold text-[16px]">
                {streak}
              </span>
            </>
          )}
        </div>
        <div className="flex-1 p-[10px] pr-0">
          <div className="flex justify-between items-center mb-[8px]">
            <p className="m-0 text-[16px]">
              {isStreakZero ? 'No streak' : `${streak} day reading streak`}
            </p>
            {!isStreakZero && 
              <div
              className={`flex items-center justify-center cursor-pointer ${
                isStreakZero ? 'pointer-events-none opacity-50' : 'opacity-100'
              }`}
              onClick={() => !isStreakZero && setModalVisible(true)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-[#3B82F6]"
              >
                <path
                  d="M8.18005 6.08V12.31V17.92C8.18005 18.88 9.34005 19.36 10.0201 18.68L15.2001 13.5C16.0301 12.67 16.0301 11.32 15.2001 10.49L13.2301 8.52L10.0201 5.31C9.34005 4.64 8.18005 5.12 8.18005 6.08Z"
                  fill="#6374FA"
                />
              </svg>
            </div>
            }
          </div>
          <div
            className={`relative h-[15px] rounded-full w-[95%] ${
              isStreakZero 
                ? 'bg-[#E5C29F]' 
                : colorScheme === 'dark' 
                  ? 'bg-[#50402C]' 
                  : 'bg-[#FFF2DC]'
            }`}
          >

            <div
              className={`h-full rounded-full transition-all duration-300`}
              style={{
                width: `${progress}%`,
                backgroundColor: isStreakZero ? '#946521' : '#FC9502',
                opacity: isStreakZero ? 0.7 : 1,
              }}
            ></div>
            <div className="absolute top-1/2 left-0 w-full h-full transform -translate-y-1/2">
              {thresholds.map((threshold) => (
                <span
                  key={threshold}
                  className={`absolute w-[20px] h-[20px] left-[-1px] rounded-full border transform -translate-x-1/2 -translate-y-1/2 top-1/2 ${
                    isStreakZero
                      ? 'border-[#946521] bg-[#E5C29F] opacity-40'
                      : progress >= (threshold / maxStreak) * 100
                      ? 'border-[#946521] bg-[#FFF2DC]'
                      : colorScheme === 'dark'
                      ? 'border-[#FF7F50] bg-[#303034] opacity-90'
                      : 'border-[#FF7F50] bg-[#FFF2DC] opacity-90'
                  }`}
                  style={{
                    left: `${(threshold / maxStreak) * 100}%`,
                    transition: 'background-color 0.3s ease, border-color 0.3s ease',
                  }}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreakProgress;
