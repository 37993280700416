import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Box, Text, Space, Button } from '@mantine/core';
import StreakProgress from './StreakProgress';
import { callGETAPI } from '../../utils/NetworkUtil';
import { ArrowRight, ArrowRightFilled, FireBackIcon, FireBackIconDark, FireSteak, StreakShareImage, StreakShareImagePost } from '../../assets/images';
import { useNews } from '../../context/newsProvide';
import branch from 'branch-sdk';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

export default function NewsType() {
  const [streak, setStreak] = useState(0);
  const { streakCount, setStreakCount } = useNews();
  const [modalVisible, setModalVisible] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [shareImageURL, setShareImageURL] = useState('');
  const [sharePostImageURL, setSharePostImageURL] = useState('');
  const isMobile = window.innerWidth <= 2500;
  const { colorScheme } = useTheme();
  const [deeplinkUrl, setDeeplinkUrl] = useState('');
  const isShareInitialized = useRef(false);

  const fetchStreakCount = useCallback(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData && userData.user) {
      const url = `${process.env.REACT_APP_BASE_URL}/api/streak/?user=${userData.user}`;
      callGETAPI(
        url,
        (response) => {
          const data = response.data;
          if (data && data.streak) {
            setStreak(data.streak);
            setStreakCount(data.streak);
            const previousStreak = parseInt(localStorage.getItem('previousStreak')) || 0;
            if (data.streak > previousStreak) {
              localStorage.setItem('previousStreak', data.streak);
              localStorage.setItem('streakDate', new Date().toISOString());

              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    type: 'UPDATE_STREAK_MODAL_DATE',
                    data: {
                      streakDate: new Date().toISOString(),
                      streakCount: data.streak,
                    },
                  })
                );
              }

              if (Date.now() - new Date(localStorage.getItem('streakDate')).getTime() > 24 * 60 * 60 * 1000) {
                setModalVisible(true);
              }
            }
          }
        },
        (error) => {
          console.error('Failed to fetch streak count:', error);
        }
      );
    }
  }, [setStreak, setStreakCount]);

  useEffect(() => {
    const previousStreak = parseInt(localStorage.getItem('previousStreak')) || 0;
    fetchStreakCount();
  }, [fetchStreakCount]);

  const createShareableImages = () => {
    const createImage = (baseImage, setImageURL) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      const img = new Image();
      img.src = baseImage;
      const logoImg = new Image();
      const fireImg = new Image();
      fireImg.src = FireSteak;

      Promise.all([new Promise((resolve) => (img.onload = resolve)), new Promise((resolve) => (fireImg.onload = resolve))]).then(() => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);

        const logoWidth = 300;
        const logoHeight = 90;
        context.drawImage(logoImg, 50, 50, logoWidth, logoHeight);

        const fireWidth = 100;
        const fireHeight = 100;
        const fireX = canvas.width - fireWidth - 250;
        const fireY = canvas.height / 1.9 - fireHeight / 2;

        context.font = 'bold 72px Arial';
        context.fillStyle = '#D96D0F';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(`${streak}`, fireX + fireWidth / 2, fireY + fireHeight / 2);

        context.font = '500 56px Arial';
        context.fillStyle = 'white';
        context.fillText(`${streak}`, 90, canvas.height / 1.81);

        const dataURL = canvas.toDataURL('image/png');
        setImageURL(dataURL);
      });
    };

    createImage(StreakShareImage, setShareImageURL);
    createImage(StreakShareImagePost, setSharePostImageURL);
  };

  const initializeShare = useCallback(() => {
    if (isShareInitialized.current) return;

    const domain = window.location.origin;
    const isWebView = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /Mobile|Android|iPhone|iPad|iPod/.test(userAgent) && /wv|WebView|FBAN|FBAV/.test(userAgent);
    };
    const source = isWebView() ? 'webview' : 'web';
    const linkData = {
      channel: source,
      feature: 'sharing',
      data: {
        $desktop_url: domain,
        $og_redirect: domain,
        $fallback_url: domain,
        $og_title: 'My News Reading Streak',
        $og_description: `I've maintained a ${streak}-day news reading streak!`,
        navigate_to: 'news/',
        $android_deeplink_path: 'news/',
        $android_url: 'com.ajeei.padhai://news/',
        event_type: 'news_by_type',
        dl_news_type: 'The Hindu',
      },
    };

    branch.link(linkData, (err, link) => {
      if (err) {
        console.error('Error creating link:', err);
      } else {
        setDeeplinkUrl(link);
        isShareInitialized.current = true;
      }
    });
  }, [streak]);

  const handleShare = async () => {
    try {
      const shareData = {
        title: 'My News Reading Streak',
        text: `I've maintained a ${streak}-day news reading streak!`,
        url: deeplinkUrl,
      };

      // If in WebView
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'SHARE_STREAK',
            data: shareData,
          })
        );
        return;
      }

      // Regular web sharing with file
      try {
        const blob = await fetch(sharePostImageURL).then((res) => res.blob());
        const file = new File([blob], 'streak.png', { type: 'image/png' });

        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            ...shareData,
            files: [file],
          });
        } else {
          await navigator.share(shareData);
        }
      } catch (error) {
        // Fallback to sharing without file
        await navigator.share(shareData);
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  useEffect(() => {
    if (shareModalVisible) {
      isShareInitialized.current = false;
      createShareableImages();
      initializeShare();
      MixpanelEvent('streak_popup_action', { action: 'viewed', popup_type: 'achievement' });
      sendPostHogEvent('streak_popup_action', { action: 'viewed', popup_type: 'achievement' });
    }
  }, [shareModalVisible, initializeShare]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '30px', gap: '10px', width: '100%' }}>
        <StreakProgress streak={streak} setModalVisible={setModalVisible} />
      </div>

      <Modal
        size="sm"
        opened={modalVisible}
        onClose={() => setModalVisible(false)}
        centered
        zIndex={9999999999999}
        classNames={{
          body: 'bg-cardBackground rounded-[3px] text-center text-text',
        }}
        withCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="text-[18px] font-bold text-text">Reading Streak!</div>
          <div className="mb-4" />
          <div className="relative flex justify-center items-center">
            <img
              src={colorScheme === 'dark' ? FireBackIconDark : FireBackIcon}
              alt="Streak Fire Background"
              className="relative w-[150px] h-[150px] z-0"
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center justify-center z-10">
              <img src={FireSteak} alt="Streak Fire" className="w-[80px] h-[80px] relative" />
              <div className="absolute top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[1.4rem] font-bold text-white">
                {streak}
              </div>
            </div>
          </div>
          <div className="text-[1rem] font-poppins text-[#7B7B7B] mt-[10px]">
            Congratulations! You're on a {streak}-day streak with daily news summary reading.
          </div>
          <div className="mt-6" />
          <div className="flex flex-col gap-4">
            <button
              onClick={() => {
                setModalVisible(false);
                setShareModalVisible(true);
                MixpanelEvent('streak_popup_action', { action: 'clicked', popup_type: 'achievement' });
                sendPostHogEvent('streak_popup_action', { action: 'clicked', popup_type: 'achievement' });
              }}
              className="w-[296px] h-[52px] py-[16px] px-[24px] flex justify-center items-center gap-[8px] rounded-[20px] border border-border bg-text text-background"
            >
              Share Achievement
              <img src={colorScheme === "dark"? ArrowRightFilled : ArrowRight} alt="arrow" />
            </button>
            <button
              onClick={() => {
                setModalVisible(false);
                MixpanelEvent('screen_closed', { source_screen: 'Reading Streak!', close_type: 'modal' });
                sendPostHogEvent('streak_popup_action', { action: 'dismissed', popup_type: 'achievement' });
              }}
              className="bg-transparent text-text font-bold py-[10px] px-[20px] rounded-[10px]"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        size={isMobile ? '28rem' : '22vw'}
        opened={shareModalVisible}
        onClose={() => setShareModalVisible(false)}
        centered
        zIndex={9999999999999}
        withCloseButton={false}
        classNames={{
          body: 'bg-cardBackground rounded-[3px] text-center text-text',
        }}
      >
        <div className="flex justify-center mb-4">
          <p className="text-lg font-medium text-center">Sharing One Image</p>
        </div>
        <div>{shareImageURL && <img src={shareImageURL} alt="Shareable Streak" className="w-full h-full" />}</div>
        <div className="flex justify-center mt-4">
          {isShareInitialized.current ? (
            <button
              onClick={handleShare}
              className={`px-6 py-3 rounded-full cursor-pointer
                ${colorScheme === 'dark' ? 'text-white border border-white' : ' text-black border border-black'}`}
            >
              Share
            </button>
          ) : (
            <p>Preparing share...</p>
          )}
        </div>
      </Modal>
    </>
  );
}
