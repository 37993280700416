// analytics.js
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

// Helper to check if we're in WebView
const isWebView = () => {
  return !!window.ReactNativeWebView;
};

// Initialize Mixpanel
const initMixpanel = () => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }
  try {
    if (!isWebView()) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_API_KEY, {
        debug: true,
        track_pageview: false,
        persistence: 'localStorage',
        loaded: (mixpanel) => {
          console.log('Mixpanel loaded');
          window.mixpanel = mixpanel;
        },
      });
    }
  } catch (error) {
    console.error('Error initializing Mixpanel:', error);
  }
};

const initPostHog = () => {
  if (!isWebView()) {
    posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
    });
  }
};

const sendPostHogEvent = (eventName, eventProps = {}) => {
  try {
    const enhancedProps = {
      ...eventProps,
      platform: isWebView() ? 'webview' : 'web',
      timestamp: new Date().toISOString()
    };

    if (isWebView()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'TRACK_EVENT',
        eventName,
        properties: enhancedProps
      }));
    } else {
      let props = { ...enhancedProps };
      props.mixpanelID = mixpanel?.get_distinct_id();
      posthog?.capture(eventName, props);
    }
  } catch (error) {
    console.error('PostHog event error:', error);
  }
};

// Send a page change event
const sendPageChangeEvent = (pageName = '', breakpoint = '') => {
  let params = {
    pageName: pageName,
    platform: isWebView() ? 'webview' : 'web',
    timestamp: new Date().toISOString()
  };
  if (breakpoint !== '') {
    params.breakpoint = breakpoint;
  }
  sendEvent('Page_Enter', params);
};

// Send a generic event
const sendEvent = (eventName = '', params = {}) => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }

  const enhancedParams = {
    ...params,
    platform: isWebView() ? 'webview' : 'web',
    timestamp: new Date().toISOString()
  };

  try {
    if (isWebView()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'TRACK_EVENT',
        eventName,
        properties: enhancedParams
      }));
    } else {
      console.log('sendEvent', eventName, enhancedParams);
      mixpanel?.track(eventName, enhancedParams);
    }
  } catch (error) {
    console.error('Mixpanel event error:', error);
  }
};

// Identify a user
const identifyUser = (user_id = '') => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }
  try {
    if (isWebView()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'IDENTIFY_USER',
        userId: user_id
      }));
    } else {
      mixpanel?.identify(user_id);
    }
  } catch (error) {
    console.error('Mixpanel identify error:', error);
  }
};

// Set user properties
const setMixpanelUserProperties = (params = {}) => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return;
  }
  try {
    if (isWebView()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'SET_USER_PROPERTIES',
        properties: params
      }));
    } else {
      mixpanel?.people?.set(params);
    }
  } catch (error) {
    console.error('Mixpanel properties error:', error);
  }
};

const setPostHogUserProperties = (params = {}) => {
  try {
    if (isWebView()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'SET_POSTHOG_USER_PROPERTIES',
        properties: params
      }));
    } else {
      posthog?.identify(params);
    }
  } catch (error) {
    console.error('PostHog properties error:', error);
  }
};

// Get Mixpanel distinct ID
const getMixpanelDistinctID = () => {
  if (process.env.REACT_APP_IS_AUTOMATED_TESTING === 'true') {
    return '';
  }
  try {
    if (isWebView()) {
      return ''; // Handle this differently if needed in WebView
    }
    return mixpanel?.get_distinct_id() || '';
  } catch (error) {
    console.error('Mixpanel ID error:', error);
    return '';
  }
};

// Unified event tracking function
const MixpanelEvent = (eventName, properties = {}) => {
  const enhancedProperties = {
    ...properties,
    platform: isWebView() ? 'webview' : 'web',
    timestamp: new Date().toISOString()
  };

  try {
    if (isWebView()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        type: 'TRACK_EVENT',
        eventName,
        properties: enhancedProperties
      }));
    } else {
      mixpanel?.track(eventName, enhancedProperties);
    }
  } catch (error) {
    console.error('Mixpanel event error:', error);
  }
};

// Export all utilities
export {
  initMixpanel,
  sendPageChangeEvent,
  sendEvent,
  identifyUser,
  setMixpanelUserProperties,
  getMixpanelDistinctID,
  MixpanelEvent,
  initPostHog,
  sendPostHogEvent,
  setPostHogUserProperties,
};
