// NewsContext.js
import moment from 'moment';
import React, { createContext, useState, useContext, useEffect } from 'react';

const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
  const [newsData, setNewsData] = useState([]);
  const [newsDetailData, setNewsDetailData] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
  const [readingTime, setReadingTime] = useState(null);
  const [streakCount, setStreakCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSelectedDate = (date) => {
    setSelectedDate(date);
  };

  const handleReadingTime = (time) => {
    setReadingTime(time);
  };

  useEffect(() => {
    if (newsDetailData && newsData.length > 0) {
      setCurrentIndex(newsData.findIndex((item) => item.id === newsDetailData.id));
    }
  }, [newsDetailData, newsData]);

  return (
    <NewsContext.Provider
      value={{
        newsData,
        setNewsData,
        newsDetailData,
        setNewsDetailData,
        selectedDate,
        setSelectedDate: handleSelectedDate,
        readingTime,
        setReadingTime: handleReadingTime,
        streakCount,
        setStreakCount,
        currentIndex,
        setCurrentIndex,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

export const useNews = () => useContext(NewsContext);
