import React, { useEffect, useState, useMemo, useRef } from 'react';
import CustomDatePicker from './datePicker';
import NewsType from './newsType';
import { ReadingTime } from './readingTime';
import NewsListItem from './newsCard';
import { callGETAPI } from '../../utils/NetworkUtil';
import { useNavigate, useLocation } from 'react-router-dom';
import { getNews } from '../../utils/NewsFeedUtils';
import { useNews } from '../../context/newsProvide';
import NoDataLatestNews from '../../molecules/NoData/noDataLatestNews';
import { useTheme } from '../../context/themeProvider';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';

export default function LatestNews() {
  const { colors, colorScheme } = useTheme();
  const location = useLocation();
  const newsListRef = useRef(null);
  const windowHeight = window.innerHeight;
  const isResponsiveMobile = windowHeight <= 700;
  const userData = useMemo(() => JSON.parse(localStorage.getItem('userData')), []);
  const { setNewsData, selectedDate, setSelectedDate, readingTime, streakCount, setReadingTime } = useNews();
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const navigate = useNavigate();
  const { dl_start_date, dl_end_date, dl_news_paper, dl_reading_time } = location.state || {};
  const [readIds, setReadIds] = useState([]);

  useEffect(() => {
    const newsListElement = newsListRef.current;
    if (!newsListElement) return;

    // Custom debounce function
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };

    // Debounced scroll handler
    const handleScrollSave = debounce(() => {
      localStorage.setItem('newsListScrollPosition', newsListElement.scrollTop.toString());
    }, 200);

    // Add scroll event listener
    newsListElement.addEventListener('scroll', handleScrollSave, { passive: true });

    // Restore scroll position
    const savedPosition = localStorage.getItem('newsListScrollPosition');
    if (savedPosition) {
      requestAnimationFrame(() => {
        newsListElement.scrollTop = parseInt(savedPosition, 10);
        localStorage.removeItem('newsListScrollPosition');
      });
    }

    // Cleanup
    return () => {
      newsListElement.removeEventListener('scroll', handleScrollSave);
    };
  }, []);

  useEffect(() => {
    if (dl_start_date && dl_start_date !== selectedDate) {
      setSelectedDate(dl_start_date);
    }
    if (dl_reading_time && dl_reading_time !== readingTime) {
      setReadingTime(dl_reading_time);
    }
  }, [dl_start_date, dl_reading_time]);

  const {
    data: newsDataFetched,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['allNews', userData?.user, selectedDate, readingTime, selectedNews],
    queryFn: async () => {
      const newsList = await getNews({ userId: userData.user, selectedNews, date: selectedDate, readingTime });
      setNewsData(newsList);
      return newsList;
    },
    staleTime: 0,
    cacheTime: 0,
  });

  const getPreviousDay = (date) => {
    return moment(date).subtract(1, 'day').format('YYYY-MM-DD');
  };

  useEffect(() => {
    if (selectedDate && userData.user) {
      refetch();
    }
  }, [selectedDate, readingTime, userData]);

  useEffect(() => {
    if (userData && userData.user) {
      const idsString = userData.user;
      const summaryCountUrl = `${process.env.REACT_APP_BASE_URL}/api/user-summary-data/?user=${idsString}`;

      callGETAPI(
        summaryCountUrl,
        (resp) => {
          setReadIds(resp.data.read || []);
        },
        (err) => {
          console.error('Summary count API error:', err);
        }
      );
    }
  }, [userData]);

  useEffect(() => {
    if (location.state && location.state.scrollPosition) {
      setTimeout(() => {
        newsListRef.current.scrollTo(0, location.state.scrollPosition);
      }, 0);
    }
  }, [location]);

  const onSetDate = (date) => {
    MixpanelEvent('news_date_selector_submitted', { selected_date: date });
    sendPostHogEvent('news_date_selector_submitted', { selected_date: date });
    if (date && date !== selectedDate) {
      setSelectedDate(date);
    }
  };

  const handleNewsClick = (newsItem, index, allNewsIds) => {
    const scrollPosition = newsListRef.current.scrollTop;
    localStorage.setItem('newsListScrollPosition', scrollPosition);

    MixpanelEvent('news_clicked', {
      newspaper_name: selectedNews,
      source: 'LatestNews',
      date: selectedDate.split('-').reverse().join(''),
      reading_time: readingTime || null,
      streak: streakCount,
      news_id: newsItem.id,
      tab: 'LatestNews',
      dark_mode: colorScheme === 'dark',
      topic: newsItem.gpt_output.mains_syllabus.join(', ') || 'N/A',
    });

    sendPostHogEvent('news_clicked', {
      newspaper_name: selectedNews,
      source: 'LatestNews',
      date: selectedDate.split('-').reverse().join(''),
      reading_time: readingTime || null,
      news_id: newsItem.id,
      tab: 'LatestNews',
    });

    navigate(`/news/${newsItem.id}`, {
      state: {
        newsItem,
        newsType: selectedNews,
        currentIndex: index + 1,
        totalArticles: newsDataFetched?.length ?? 0,
        scrollPosition,
        selectedDate,
        readingTime,
        streakCount,
        allNewsIds
      },
    });
  };

  return (
    <div
      style={{
        backgroundColor: colors.background,
        color: colors.text,
        height: windowHeight > 900 ? '100vh' : isResponsiveMobile ? '33em' : '45em',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="flex justify-center p-3 z-10">
        <NewsType selectedNews={selectedNews} setSelectedNews={setSelectedNews} />
      </div>
      <div className="flex flex-row gap-6 justify-between px-3 py-2 z-10">
        <CustomDatePicker selectedNews={selectedNews} onSetDate={onSetDate} apiDate={selectedDate} />
        <ReadingTime />
      </div>
      <div className="scrollbar-hidden p-4 overflow-y-scroll h-screen z-[1]" ref={newsListRef}>
        {newsDataFetched?.length > 0 ? (
          newsDataFetched.map((newsItem, index) => (
            <NewsListItem
              key={newsItem.id}
              tags={newsItem.gpt_output.mains_syllabus}
              title={newsItem.title}
              likes={newsItem.likes}
              bookmarks={newsItem.bookmarks}
              imageSrc={newsItem.imageSrc}
              newsItem={newsItem}
              newsType={selectedNews}
              onClick={() => handleNewsClick(newsItem, index, newsDataFetched.map(n => n.id))}
              isLastItem={index === newsDataFetched?.length - 1}
              readIds={readIds}
            />
          ))
        ) : (
          <div
            style={{
              textAlign: 'center',
              color: colors.noDataText,
              paddingTop: '2rem',
            }}
          >
            <NoDataLatestNews />
          </div>
        )}
      </div>
    </div>
  );
}
