const FireFoxModal = ({ setShowFirefoxModal }) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[2000] flex items-center justify-center px-4">
            <div className="bg-cardBackground rounded-xl p-6 w-full max-w-[26em] relative">
                <div className="space-y-6">
                    <h3 className="text-lg text-text font-medium text-center">Installation Guide</h3>
                    <p className="text-text text-center">Currently, installation is supported only on Safari and Chrome browsers.</p>
                    <button onClick={() => setShowFirefoxModal(false)} className="w-full py-3 bg-border rounded-full text-text font-bold text-800">
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FireFoxModal