// create a loader component with a logo and a text

import { LoginHomeLogo } from '../assets/images';

const AppLoader = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <img src={LoginHomeLogo} alt="Loading" className="h-18 animate-pulse duration-100" />
    </div>
  );
};

const AppLoaderContent = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full ">
      <img src={LoginHomeLogo} alt="Loading" className="h-18 animate-pulse" />
    </div>
  );
};

const LoadingSpinnerScreen = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="relative">
        <div className="w-12 h-12 rounded-full"></div>
        <div className="h-12 w-12  border-t-2 border-b-2 border-[#6374FA] rounded-full animate-spin absolute top-0 left-0"></div>
      </div>
    </div>
  );
};

export { AppLoader, AppLoaderContent, LoadingSpinnerScreen };
