import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as appRating from "../assets/json/in_app_rating.json";
import { AppRatingBack } from "../assets/images";
import { useTheme } from "../context/themeProvider";
import { userStore } from "../stores/userStore";

const RatingModal = ({
  visible,
  onClose,
  source
}) => {
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const { colorScheme } = useTheme();
  const [canShowModal, setCanShowModal] = useState(false);
  const userData = userStore((state) => state.userData);
  const userId = userData.user;

  const checkRatingConditions = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/track/in-app-rating/?user=${userId}`,
        { method: "GET", headers: { 'Content-Type': 'application/json' }, },
      )
      const data = await res.json();
      const lastPopupTimeStr = await localStorage.getItem('lastPopupTime');
      const hasRated = await localStorage.getItem('hasRated');
      const lastCloseTime = await localStorage.getItem('lastCloseTime');

      if (hasRated === 'true') {
        return setCanShowModal(false);
      }

      if (lastCloseTime) {
        const lastClose = new Date(lastCloseTime).getTime();
        const currentTime = new Date().getTime();
        const twentyFourHours = 24 * 60 * 60 * 1000;

        if (currentTime - lastClose < twentyFourHours) {
          return setCanShowModal(false);
        }
      }

      if (data?.length > 0 || lastPopupTimeStr) {
        const lastEntry = data[0];
        let lastPopupTime = new Date(lastEntry?.last_popup_time).getTime();
        const currentTime = new Date().getTime();
        const twoWeeks = 14 * 24 * 60 * 60 * 1000;
        if (!lastPopupTime && lastPopupTimeStr) {
          lastPopupTime = new Date(lastPopupTimeStr).getTime();
        }
        if (!lastEntry?.api_called && currentTime - lastPopupTime >= twoWeeks) {
          setTimeout(() => {
            setCanShowModal(true);
          }, 1000);
        }
        else {
          setCanShowModal(false);
          onClose()
        }
      } else {
        setTimeout(() => {
          setCanShowModal(true);
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRatingSubmission = () => {
    if (rating === 5) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'PLAY_STORE_RATING_MODAL',
          })
        );
      }
      postRatingData(true);
    } else {
      postRatingData(true);
    }
    onClose();
  };

  const postRatingData = async (playstoreAction) => {
    try {
      const payload = {
        user: userId,
        source: source,
        in_app_rating: rating,
        api_called: playstoreAction
      };

      await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/track/in-app-rating/`,
        {
          method: "POST",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload)
        }
      );
      setCanShowModal(false)
      localStorage.setItem('lastPopupTime', new Date().toISOString());
      localStorage.setItem('hasRated', 'true');
    } catch (error) {
      console.error('Error posting rating:', error);
    }
  };

  useEffect(() => {
    if (userId && visible) {
      checkRatingConditions();
    }
  }, [userId, visible]);

  if (!visible && !source || !canShowModal) return null;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: appRating,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const isWindow = window.innerWidth <= 2500;

  const handleRatingClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const StarBorder = ({ size, color }) => (
    <svg height={size} viewBox="0 0 24 24" width={size}>
      <path
        fill={color}
        d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"
      />
    </svg>
  );

  const StarFull = ({ size, color }) => (
    <svg height={size} viewBox="0 0 24 24" width={size}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill={color}
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
      />
    </svg>
  );

  const labelColor = colorScheme === "dark" ? "text-[#B9B9B9]" : "text-[#747474]"

  const handleClose = () => {
    localStorage.setItem('lastCloseTime', new Date().toISOString());
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'CLOSE_RATING_MODAL',
        })
      );
    }
    setCanShowModal(false);
    onClose();
  };

  return (
    canShowModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end z-[9999]" onClick={handleClose}>
        <div
          className={`relative bg-cardBackground p-6 rounded-t-xl shadow-lg w-full font-poppins ${isWindow ? "max-w-[28rem]" : "max-w-[22vw]"
            } text-center`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-center mb-4">
            <span
              className="w-[89px] h-[6px] bg-gray-300 rounded-full cursor-pointer"
              onClick={handleClose}
            ></span>
          </div>

          <div className="flex justify-center relative mb-4">
            <img
              src={AppRatingBack}
              alt="App Rating Background"
              className="absolute w-[calc(100%-40px)] left-5 right-5"
            />
            <Lottie options={defaultOptions} height={230} width={230} />
          </div>

          <div className="mt-2 mb-4">
            <h2 className="text-2xl font-bold text-text">Are you enjoying PadhAI?</h2>
            <p className={`mt-2 ${labelColor}`}>
              Share the love! Rate us on Play Store.
              <br />
              Your feedback matters!
            </p>
          </div>

          <div className="flex justify-center space-x-2 my-6">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                key={star}
                className="focus:outline-none transition-transform transform hover:scale-110"
                onMouseEnter={() => setHoveredRating(star)}
                onMouseLeave={() => setHoveredRating(0)}
                onClick={() => handleRatingClick(star)}
              >
                {star <= (hoveredRating || rating) ? (
                  <StarFull size={48} color="#FBBF24" />
                ) : (
                  <StarBorder size={48} color="#FBBF24" />
                )}
              </button>
            ))}
          </div>

          <div className="flex gap-4 mt-6">
            <button
              onClick={handleClose}
              className="py-4 px-8 rounded-full border border-text text-text font-medium transition-colors"
            >
              Rate Later
            </button>
            <button
              onClick={handleRatingSubmission}
              className={`flex-1 py-4 px-6 rounded-full border border-text font-medium transition-colors text-text`}
            >
              Submit Rating
            </button>
          </div>
        </div>
      </div>
    )

  );
};

export default RatingModal;