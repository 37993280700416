import { create } from 'zustand';
export const userStore = create((set) => ({
  userData: JSON.parse(localStorage.getItem('userData')) || {},

  // Update userData and save to localStorage
  updateUserData: (data) => {
    set((state) => {
      const updatedData = { ...state.userData, ...data };
      localStorage.setItem('userData', JSON.stringify(updatedData));
      return { ...state, userData: updatedData };
    });
  },

  setUserData: (data) => {
    set((state) => {
      localStorage.setItem('userData', JSON.stringify(data));
      return { ...state, userData: data };
    });
  },

  clearUserData: () => {
    set(() => {
      localStorage.removeItem('userData');
      return { userData: {} };
    });
  },
}));
