import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import '@mantine/core/styles.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import branch from 'branch-sdk';
import useInstallPromptStore from './stores/eventListenerStore';
import InstallModal from './molecules/InstallModal';
import SafariModal from './molecules/SafariModal';
import FireFoxModal from './molecules/FireFoxModal';
import { initMixpanel, initPostHog, MixpanelEvent, sendPostHogEvent } from './utils/mixpanelUtil';

function Main() {
  const [isPwaInstall, setPwaInstall] = useState(false);
  const [showSafariModal, setShowSafariModal] = useState(false);
  const [showFirefoxModal, setShowFirefoxModal] = useState(false);
  const deferredPrompt = useInstallPromptStore((state) => state.deferredPrompt);
  const clearDeferredPrompt = useInstallPromptStore((state) => state.clearDeferredPrompt);
  const setDeferredPrompt = useInstallPromptStore((state) => state.setDeferredPrompt);
  const isPromptFired = useInstallPromptStore((state) => state.isPromptFired);
  const userData = JSON.parse(localStorage.getItem('userData')) || {};
  const userId = userData?.user ? true : false;

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      window.sessionStorage.setItem('deferredPromptFired', true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [setDeferredPrompt]);

  const removeData = () => {
    if (userId) {
      window.sessionStorage.removeItem('deeplink_data');
    } else {
      localStorage.removeItem('deeplink_data');
    }
  };

  useEffect(() => {
    try {
      initMixpanel();
      initPostHog();
      branch.init(process.env.REACT_APP_BRANCH_KEY, (err, data) => {
        if (err) {
          console.error('Branch initialization failed:', err);
          if (err.code === 'NO_CONNECTION' || err.message.includes('Request blocked by client, probably adblock')) {
            if (!sessionStorage.getItem('branch_redirected')) {
              sessionStorage.setItem('branch_redirected', 'true');
              window.location.href = window.location.origin;
            }
          }
          return;
        }

        const linkData = data?.data_parsed;
        if (linkData && linkData.$content_type === 'pwa install') {
          const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
          const promptFired = window.sessionStorage.getItem('deferredPromptFired');
          if (!isStandalone) {
            if (isChrome && (isPromptFired || promptFired)) {
              setPwaInstall(true);
              if (userId) {
                window.sessionStorage.setItem('deeplink_data', JSON.stringify(linkData));
              } else {
                localStorage.setItem('deeplink_data', JSON.stringify(linkData));
              }
            } else if (isSafari) {
              setShowSafariModal(true);
            } else if (isFirefox) {
              setShowFirefoxModal(true);
            }
            MixpanelEvent('pwa_clicks', {
              source: linkData['~utm_source'],
              medium: linkData['~utm_medium'],
              campaign: linkData['~utm_campaign'],
            });
            sendPostHogEvent('pwa_clicks', {
              source: linkData['~utm_source'],
              medium: linkData['~utm_medium'],
              campaign: linkData['~utm_campaign'],
            });
          }
        }
        console.log('Branch initialized successfully');
      });
    } catch (error) {
      console.error('Error initializing branch:', error);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const promptFired = window.sessionStorage.getItem('deferredPromptFired');
    if (isPromptFired || promptFired) {
      setPwaInstall(false);
    }
  }, [isPromptFired]);

  const handleInstallClick = () => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const deeplinkData = userId ? window.sessionStorage.getItem('deeplink_data') : localStorage.getItem('deeplink_data');
    const parsedData = JSON.parse(deeplinkData);
    console.log(parsedData, 'is herer');
    if (deferredPrompt && isChrome) {
      MixpanelEvent('pwa_installs', {
        source: parsedData['~utm_source'],
        browser: 'chrome',
        medium: parsedData['~utm_medium'],
        campaign: parsedData['~utm_campaign'],
      });
      sendPostHogEvent('pwa_installs', {
        source: parsedData['~utm_source'],
        browser: 'chrome',
        medium: parsedData['~utm_medium'],
        campaign: parsedData['~utm_campaign'],
      });
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setPwaInstall(false);
          console.log('User accepted the install prompt');
        } else {
          setPwaInstall(false);
          console.log('User dismissed the install prompt');
        }
        clearDeferredPrompt();
        window.sessionStorage.removeItem('deferredPromptFired');
      });
    } else if (isSafari) {
      setShowSafariModal(true);
      MixpanelEvent('pwa_install_clicked', {
        source: parsedData['~utm_source'],
        browser: 'safari',
        medium: parsedData['~utm_medium'],
        campaign: parsedData['~utm_campaign'],
      });
      sendPostHogEvent('pwa_install_clicked', {
        source: parsedData['~utm_source'],
        browser: 'safari',
        medium: parsedData['~utm_medium'],
        campaign: parsedData['~utm_campaign'],
      });
    } else if (isFirefox) {
      setShowFirefoxModal(true);
      MixpanelEvent('pwa_install_clicked', {
        source: parsedData['~utm_source'],
        browser: 'firefox',
        medium: parsedData['~utm_medium'],
        campaign: parsedData['~utm_campaign'],
      });
      sendPostHogEvent('pwa_install_clicked', {
        source: parsedData['~utm_source'],
        browser: 'firefox',
        medium: parsedData['~utm_medium'],
        campaign: parsedData['~utm_campaign'],
      });
    }
  };

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        {isPwaInstall ? (
          <InstallModal
            opened={isPwaInstall}
            onClose={() => {
              setPwaInstall(false);
              removeData();
            }}
            onInstall={handleInstallClick}
          />
        ) : (
          <App />
        )}
      </GoogleOAuthProvider>
      {showSafariModal && (
        <SafariModal
          setShowSafariModal={() => {
            setShowSafariModal(false);
            removeData();
          }}
        />
      )}
      {showFirefoxModal && (
        <FireFoxModal
          setShowFirefoxModal={() => {
            setShowFirefoxModal(false);
            removeData();
          }}
        />
      )}
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);

// Register the service worker
// serviceWorkerRegistration.register();
