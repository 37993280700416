import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { ArrowDown, ArrowDownDarKIcon } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

const CustomDatePicker = ({ selectedNews, onSetDate, apiDate }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());
  const [width, setWidth] = useState(window.innerWidth);
  const [tempSelectedDate, setTempSelectedDate] = useState(null);
  const { colorScheme } = useTheme();

  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'day').startOf('day');
  const weeks = useMemo(() => {
    const start = moment(currentDate).startOf('month').startOf('week');
    const end = moment(currentDate).endOf('month').endOf('week');
    const days = [];
    let week = [];

    for (let current = start.clone(); current.isSameOrBefore(end); current.add(1, 'days')) {
      week.push(current.clone());
      if (week.length === 7) {
        days.push(week);
        week = [];
      }
    }

    return days;
  }, [currentDate]);

  const isSelected = (date) => {
    if (tempSelectedDate) {
      return date.isSame(tempSelectedDate, 'day');
    }
    return apiDate && moment(apiDate).isSame(date, 'day');
  };

  const nextMonth = () => {
    setCurrentDate(moment(currentDate).add(1, 'month'));
  };

  const prevMonth = () => {
    setCurrentDate(moment(currentDate).subtract(1, 'month'));
  };

  const handleDateClick = (date) => {
    setTempSelectedDate(date);
  };

  const handleSelect = () => {
    if (tempSelectedDate) {
      const formattedDate = tempSelectedDate.format('YYYY-MM-DD');
      onSetDate(formattedDate);
    }
    setShowCalendar(false);
    setTempSelectedDate(null);
  };

  const handleCancel = () => {
    setShowCalendar(false);
    setTempSelectedDate(null);
  };

  const isAfterToday = (date) => date.isAfter(today, 'day');

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const dynamicWidth = width < 768 ? '20em' : '24em';

  const formatDisplayDate = (date) => {
    if (!date) {
      return moment().format('D MMM YYYY');
    }
    return moment(date).format('D MMM YYYY');
  };

  return (
    <div className="relative rounded-[12px] flex-1">
      <button
        className="w-full h-12 py-2 text-left text-sm border border-border rounded-lg shadow-md flex items-center justify-between gap-2"
        onClick={() => setShowCalendar(!showCalendar)}
      >
        <span className="p-2 ml-[5px]">{formatDisplayDate(apiDate)}</span>
        <img src={colorScheme === 'dark' ? ArrowDownDarKIcon : ArrowDown} alt="Arrow down icon" className="w-3.5 ml-2 mr-2" />
      </button>

      {showCalendar && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]" onClick={handleCancel}>
          <div
            className="bg-cardBackground border border-border rounded-lg shadow-lg p-4"
            style={{ width: dynamicWidth }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <button onClick={prevMonth} className="p-2 hover:bg-border rounded-full transition-colors duration-200">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <span className="font-medium">{currentDate.format('MMMM YYYY')}</span>
              <button onClick={nextMonth} className="p-2 hover:bg-border rounded-full transition-colors duration-200">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-7 gap-2 mb-3">
              {moment.weekdaysMin().map((day) => (
                <div key={day} className="text-xs text-center font-medium text-gray-500">
                  {day.toUpperCase()}
                </div>
              ))}
            </div>

            <div className="grid gap-2">
              {weeks.map((week, weekIndex) => (
                <div key={weekIndex} className="grid grid-cols-7 gap-2">
                  {week.map((date, dateIndex) => {
                    const isCurrentMonth = date.isSame(currentDate, 'month');
                    const isDisabled = isAfterToday(date) || !isCurrentMonth;

                    return (
                      <button
                        key={dateIndex}
                        onClick={() => !isAfterToday(date) && handleDateClick(date)}
                        disabled={isAfterToday(date)} // Disable only future dates
                        className={`
                        h-10 w-10 rounded-full flex items-center justify-center text-sm
                        ${!isCurrentMonth ? 'text-gray-100' : ''}
                        ${isSelected(date) ? 'bg-[#6374FA] text-white' : ''}
                        ${isAfterToday(date) ? 'text-gray-500 cursor-not-allowed' : ''}
                        ${!isAfterToday(date) && !isSelected(date) ? 'hover:bg-[#6374FA] hover:text-white' : ''}
                        disabled:hover:bg-transparent
                        transition-colors duration-200
                      `}
                      >
                        {date.date()}
                      </button>
                    );
                  })}
                </div>
              ))}
            </div>

            <div className="mt-6 flex justify-center gap-4">
              <button
                className={`text-sm text-text hover:opacity-80 transition-opacity duration-200 border border-border px-4 py-2 rounded-full ${
                  isSelected(today) ? 'bg-[#6374FA] text-white' : ''
                }`}
                onClick={() => handleDateClick(today)}
              >
                Today
              </button>
              <button
                className={`text-sm text-text hover:opacity-80 transition-opacity duration-200 border border-border px-4 py-2 rounded-full ${
                  isSelected(yesterday) ? 'bg-[#6374FA] text-white' : ''
                }`}
                onClick={() => handleDateClick(yesterday)}
              >
                Yesterday
              </button>
            </div>

            <div className="mt-6 flex justify-between">
              <button onClick={handleCancel} className="px-4 py-2 text-sm text-text hover:opacity-80 transition-opacity duration-200">
                Cancel
              </button>
              <button
                onClick={handleSelect}
                disabled={!tempSelectedDate}
                className="px-4 py-2 text-sm bg-text text-background rounded-full hover:bg-opacity-90 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Select Date
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
