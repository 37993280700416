import React, { useState } from 'react';
import LatestNews from '../../components/News/latestNews';
import TodaysQuestions from '../../components/News/todaysQuestions';
import SavedTab from '../../components/News/saved';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';
import SwipeableContainer from '../SwipeableContainer';

export default function Breadcrumbs({ setActiveTab }) {
  const [activeTab, setActiveTabState] = useState(() => {
    return localStorage.getItem('newsActiveTab') || 'Latest News';
  });

  const [activeNewsTab, setActiveNewsTabState] = useState(() => {
    const storedTab = localStorage.getItem('newsActiveTabIndex');
    return storedTab ? parseInt(storedTab) : 0;
  });

  const { colorScheme } = useTheme();

  const newsTabs = [
    {
      label: 'Latest News',
      component: <LatestNews />,
    },
    {
      label: "Today's Questions",
      component: <TodaysQuestions />,
    },
    {
      label: 'Saved',
      component: <SavedTab />,
    },
  ];

  const onSwipeLeft = () => {
    setActiveNewsTabState((prev) => {
      const newIndex = prev + 1 < newsTabs.length ? prev + 1 : prev;
      handleTabChange(newIndex);
      return newIndex;
    });
  };

  const onSwipeRight = () => {
    setActiveNewsTabState((prev) => {
      const newIndex = prev - 1 >= 0 ? prev - 1 : prev;
      handleTabChange(newIndex);
      return newIndex;
    });
  };

  const handleTabChange = (value) => {
    const selectedTab = newsTabs[value];
    
    setActiveTabState(selectedTab.label);
    setActiveNewsTabState(value);
    setActiveTab(selectedTab.label);

    localStorage.setItem('newsActiveTab', selectedTab.label);
    localStorage.setItem('newsActiveTabIndex', value);
    MixpanelEvent('tab_selection', { tab_name: selectedTab.label, source: 'news' });
    sendPostHogEvent('tab_selection', { tab_name: selectedTab.label, source: 'news' });
  };

  const getTabStyle = (tabIndex) => ({
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: activeNewsTab === tabIndex ? '2px solid blue' : '1px solid transparent',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
    background: 'none',
    outline: 'none',
    color: activeNewsTab === tabIndex ? (colorScheme === 'dark' ? 'white' : 'black') : colorScheme === 'dark' ? '#8B8B8B' : '#747474',
  });

  return (
    <div className="relative top-[60px] w-full z-[99] bg-background">
      <div className="flex justify-between items-center w-full">
        <div className="w-full">
          <div className="flex justify-evenly z-10 shadow-md mb-1">
            {newsTabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => handleTabChange(index)}
                style={{ ...getTabStyle(index), whiteSpace: 'nowrap' }}
                className={`text-[14px] transition-all duration-300 ${index === 'todays-questions' ? 'ml-2' : ''}`}
              >
                {tab.label}
              </button>
            ))}
          </div>

          <SwipeableContainer className="flex flex-col h-full" onSwipeLeft={onSwipeLeft} onSwipeRight={onSwipeRight}>
            {newsTabs[activeNewsTab].component}
          </SwipeableContainer>
        </div>
      </div>
    </div>
  );
}