// stores/dataStore.js
import { create } from 'zustand';

export const useDataStore = create((set) => ({
  // Highlights data
  highlights: {},
  highlightsLoading: true,
  highlightsTotalPages: 1,
  highlightsCurrentPage: 1,
  highlightsCache: {}, // Cache for each page

  // Bookmarks data
  bookmarks: [],
  bookmarksLoading: true,
  bookmarksTotalPages: 1,
  bookmarksCurrentPage: 1,
  bookmarksCache: {}, // Cache for each page

  // Highlights actions
  setHighlights: (highlights) => set({ highlights }),
  setHighlightsLoading: (loading) => set({ highlightsLoading: loading }),
  setHighlightsTotalPages: (pages) => set({ highlightsTotalPages: pages }),
  setHighlightsCurrentPage: (page) => set({ highlightsCurrentPage: page }),
  cacheHighlightsPage: (page, data) => 
    set((state) => ({ 
      highlightsCache: { ...state.highlightsCache, [page]: data } 
    })),
  
  // Invalidate highlights cache to force refresh
  invalidateHighlightsCache: () => 
    set({ highlightsCache: {} }),

  // Bookmarks actions
  setBookmarks: (bookmarks) => set({ bookmarks }),
  setBookmarksLoading: (loading) => set({ bookmarksLoading: loading }),
  setBookmarksTotalPages: (pages) => set({ bookmarksTotalPages: pages }),
  setBookmarksCurrentPage: (page) => set({ bookmarksCurrentPage: page }),
  cacheBookmarksPage: (page, data) => 
    set((state) => ({ 
      bookmarksCache: { ...state.bookmarksCache, [page]: data } 
    })),
  
  // Invalidate bookmarks cache to force refresh
  invalidateBookmarksCache: () => 
    set({ bookmarksCache: {} }),

  // Add a new highlight to the current set (when added from another screen)
  addHighlight: (newsId, highlight) =>
    set((state) => {
      const updatedHighlights = { ...state.highlights };
      
      if (!updatedHighlights[newsId]) {
        // If this is a new news item, create it
        updatedHighlights[newsId] = {
          id: newsId,
          highlights: [],
        };
      }
      
      // Add the highlight to the news item
      updatedHighlights[newsId].highlights = [
        ...updatedHighlights[newsId].highlights,
        highlight
      ];
      
      return { highlights: updatedHighlights };
    }),

  // Delete highlight
  deleteHighlight: (newsId, highlightId) => 
    set((state) => {
      const updatedHighlights = { ...state.highlights };
      
      if (updatedHighlights[newsId]) {
        const highlights = updatedHighlights[newsId].highlights.filter(
          (highlight) => highlight.id !== highlightId
        );

        if (highlights.length === 0) {
          delete updatedHighlights[newsId];
        } else {
          updatedHighlights[newsId] = {
            ...updatedHighlights[newsId],
            highlights,
          };
        }
      }
      
      return { highlights: updatedHighlights };
    }),
  
  // Add a bookmark (when added from another screen)
  addBookmark: (bookmark) =>
    set((state) => {
      // Check if this bookmark already exists
      const exists = state.bookmarks.some(item => item.id === bookmark.id);
      
      if (!exists) {
        return { bookmarks: [bookmark, ...state.bookmarks] };
      }
      
      return state;
    }),
  
  // Remove a bookmark
  removeBookmark: (bookmarkId) =>
    set((state) => ({
      bookmarks: state.bookmarks.filter(bookmark => bookmark.id !== bookmarkId)
    })),
}));