import React, { createContext, useContext, useState, useEffect, useMemo, useRef } from 'react';
import themeColors from '../themeColors';
import { MixpanelEvent, sendPostHogEvent } from '../utils/mixpanelUtil';

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const getSystemTheme = () => 
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

  const [activeTheme, setActiveTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || getSystemTheme();
  });

  const [isSystemTheme, setIsSystemTheme] = useState(() => {
    return !localStorage.getItem('theme');
  });

  const themeChangedByUser = useRef(false);
  // Listen to system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const handleChange = (e) => {
      const newSystemTheme = e.matches ? 'dark' : 'light';
      if (isSystemTheme) {
        setActiveTheme(newSystemTheme);
      }
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [isSystemTheme]);

  // Update document theme whenever activeTheme changes
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', activeTheme);
    
    if (isSystemTheme) {
      localStorage.removeItem('theme');
    } else {
      localStorage.setItem('theme', activeTheme);
    }

    if (themeChangedByUser.current) {
      MixpanelEvent('settings_dark_mode_toggled', { mode: activeTheme });
      sendPostHogEvent('settings_dark_mode_toggled', { mode: activeTheme });
      themeChangedByUser.current = false; 
    }
  }, [activeTheme, isSystemTheme]);

  const toggleTheme = () => {
    themeChangedByUser.current = true; 
    setActiveTheme((prev) => {
      const newScheme = prev === 'light' ? 'dark' : 'light';
      setIsSystemTheme(false); // User is explicitly setting a theme
      return newScheme;
    });
  };

  const resetToSystemTheme = () => {
    themeChangedByUser.current = true;
    setIsSystemTheme(true);
    setActiveTheme(getSystemTheme());
  };

  const colors = themeColors[activeTheme];

  const contextValue = useMemo(() => ({
    colorScheme: activeTheme,
    toggleTheme,
    resetToSystemTheme,
    colors,
    isSystemTheme
  }), [activeTheme, isSystemTheme]);

  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};
