import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { callGETAPI } from '../../utils/NetworkUtil';
import { userStore } from '../../stores/userStore';
import { useDataStore } from '../../stores/dataStore';
import { Card, Text, Box, Image, Button } from '@mantine/core';
import { useTheme } from '../../context/themeProvider';
import NoHighlights from './NoHighlights';
import { TheHindu, IndianExpress, Edit2, TickCircle } from '../../assets/images';
import Pagination from './pagination';

export default function MyNotes({ refreshTrigger }) {
  const [expandedNews, setExpandedNews] = useState({});
  const [editMode, setEditMode] = useState(null);
  const { colors } = useTheme();
  const userData = userStore((state) => state.userData);
  const navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  
  // Use the dataStore instead of local state
  const {
    highlights, 
    highlightsLoading, 
    highlightsTotalPages,
    highlightsCurrentPage,
    highlightsCache,
    setHighlights,
    setHighlightsLoading,
    setHighlightsTotalPages,
    setHighlightsCurrentPage,
    cacheHighlightsPage,
    deleteHighlight,
    invalidateHighlightsCache
  } = useDataStore();

  const fetchMyNote = (page) => {
    if (!userData.user_id) return;
    
    if (highlightsCache[page]) {
      setHighlights(highlightsCache[page]);
      setHighlightsLoading(false);
      return;
    }
    
    // If not cached, fetch from API
    setHighlightsLoading(true);
    callGETAPI(
      `${process.env.REACT_APP_BASE_URL}/api/highlights/?user=${userData.user_id}&page=${page}&page_size=50`,
      (response) => {
        if (response.data) {
          const grouped = response.data.results.reduce((acc, item) => {
            if (!acc[item.id]) {
              acc[item.id] = {
                ...item,
                highlights: [],
              };
            }
            acc[item.id].highlights.push(...item.highlighted_points);
            return acc;
          }, {});
          
          setHighlights(grouped);
          cacheHighlightsPage(page, grouped); // Cache the results
          setHighlightsTotalPages(Math.ceil(response.data.count / 50));
        }
        setHighlightsLoading(false);
      },
      (error) => {
        console.error('API call error:', error);
        setHighlightsLoading(false);
      }
    );
  };

  useEffect(() => {
    invalidateHighlightsCache();
    fetchMyNote(highlightsCurrentPage);
  }, [refreshTrigger]);

  useEffect(() => {
    fetchMyNote(highlightsCurrentPage);
  }, [highlightsCurrentPage, userData?.user_id]);

  useEffect(() => {
    const handleResize = () => {
      const adjustedHeight = window.innerHeight;
      setContentHeight(adjustedHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePageChange = (page) => {
    setHighlightsCurrentPage(page);
  };

  const handleCardClick = (newsItem) => {
    navigate(`/news/${newsItem.id}`, {
      state: {
        newsItem: {
          id: newsItem.id,
          highlights: newsItem.highlighted_points
        },
      },
    });
  };

  const handleDeleteClick = async (newsId, highlightId) => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/api/highlight/delete/${highlightId}/`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
      
      // Update store using the deleteHighlight action
      deleteHighlight(newsId, highlightId);
    } catch (error) {
      console.error('Error deleting highlight:', error);
    }
  };

  const toggleExpand = (news_id) => {
    setExpandedNews((prevState) => ({
      ...prevState,
      [news_id]: !prevState[news_id],
    }));
  };

  const toggleEditMode = (news_id) => {
    toggleExpand(news_id);
    setEditMode((prevState) => (prevState === news_id ? null : news_id));
  };

  if (highlightsLoading && Object.keys(highlights).length === 0) {
    return <NoHighlights />;
  }

  if (!highlightsLoading && Object.keys(highlights).length === 0) {
    return <NoHighlights />;
  }

  return (
    <>
    <div className={`bg-background text-text overflow-scroll scrollbar-hidden p-4`}
      style={{
        height: `${contentHeight - 336}px`, 
      }}
    >
      {Object.values(highlights)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) 
        .map((newsItem) => (
          <Card
            key={newsItem.id}
            shadow="sm"
            radius="md"
            withBorder
            style={{
              backgroundColor: colors.cardBackground,
              borderColor: colors.borderColor,
              marginBottom: '20px',
              position: 'relative',
              paddingBottom: '60px',
            }}
          >
            <div className="flex items-center text-center justify-between gap-[30px] mb-[10px]">
              <div className="flex items-center text-center gap-[3px]">
                <Image
                  src={newsItem.news_paper === 'The Hindu' ? TheHindu : newsItem.news_paper === 'The Indian Express' ? IndianExpress : ''}
                  alt="Newspaper Image"
                  style={{
                    height: '12px',
                  }}
                />
                <Text
                  size="sm"
                  weight={500}
                  style={{ marginLeft: '10px', color: '#747474' }}
                >
                  {new Date(newsItem.created_at).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  })}
                </Text>
              </div>
              <div>
                <img
                  onClick={() => toggleEditMode(newsItem.id)}
                  width="20px"
                  height="20px"
                  src={editMode === newsItem.id ? TickCircle : Edit2} 
                  alt="Edit Icon"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div
                className="text-text mt-[5px] bg-selectedOptionBackground flex-1"
                onClick={() => handleCardClick(newsItem)}
              >
                {1}. {newsItem.highlights[0].highlighted_content_text}
              </div>
              {editMode === newsItem.id && (
                <span
                  className="delete-icon text-red-500 text-[20px] cursor-pointer flex items-center ml-[10px]"
                  onClick={() => handleDeleteClick(newsItem.id, newsItem.highlights[0].id)}
                >
                  -
                </span>
              )}
            </div>

            {newsItem.highlights.length > 1 && (
              <button
                onClick={() => toggleExpand(newsItem.id)}
                className="absolute bottom-4 left-2 w-full text-text flex justify-start bg-transparent underline font-poppins text-xs font-semibold leading-4 text-left"
              >
                {expandedNews[newsItem.id] ? 'See Less' : `+${newsItem.highlights.length - 1} More Highlights`}
              </button>
            )}

            {expandedNews[newsItem.id] && (
              <div
                className="bg-transparent rounded-lg max-h-[300px] pt-2 overflow-y-auto mt-2"
              >
                {newsItem.highlights.slice(1).map((highlight, index) => (
                  <Box
                    key={highlight.id}
                    className="bg-transparent border-border rounded-lg mb-2 cursor-pointer flex items-center justify-between"
                  >
                    <div
                      className=" text-text bg-selectedOptionBackground flex-1"
                      onClick={() => handleCardClick(newsItem)}
                    >
                      {index + 2}. {highlight.highlighted_content_text}
                    </div>

                    {editMode === newsItem.id && (
                      <span
                        className="text-red-500 text-2xl cursor-pointer flex items-center ml-2"
                        onClick={() => handleDeleteClick(newsItem.id, highlight.id)}
                      >
                        -
                      </span>
                    )}
                  </Box>
                ))}
              </div>
            )}
          </Card>
        ))}
    </div>
    {/* Pagination Section */}
    <div className="flex-shrink-0 flex bg-background justify-center border-t border-border">
      <Pagination
        totalPages={highlightsTotalPages}
        currentPage={highlightsCurrentPage}
        onPageChange={handlePageChange}
      />
    </div>
    </>
  );
}