import { useDrag } from '@use-gesture/react';
import { debounce } from '../utils/generalUtils';

const SwipeableContainer = ({ children, onSwipeLeft, onSwipeRight, classNames }) => {
  const debouncedSwipeLeft = debounce(onSwipeLeft, 100);
  const debouncedSwipeRight = debounce(onSwipeRight, 100);

  const bind = useDrag(
    ({ direction: [dx], velocity: [vx], down }) => {
      // If the user is still swiping (down === true) or velocity is too low, do nothing
      if (down || vx < 0.3) return;

      // dx > 0 means swipe right; dx < 0 means swipe left
      if (dx > 0) {
        debouncedSwipeRight();
      } else {
        debouncedSwipeLeft();
      }
    },
    {
      filterTaps: true, // Ignore small taps
      threshold: 5, // Minimum distance to start swipe detection
    }
  );

  return (
    <div className={classNames} {...bind()}>
      {children}
    </div>
  );
};

export default SwipeableContainer;