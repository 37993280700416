import React from "react";

const InstallModal = ({ opened, onClose, onInstall }) => {
  if (!opened) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-[2000] flex items-center justify-center px-4 text-text">
      <div className="bg-cardBackground rounded-xl p-6 w-full max-w-[26em] relative">
        <h2 className="text-lg font-semibold mb-2">Install App</h2>
        <p>Would you like to install this app for a better experience?</p>
        <div className="flex justify-between mt-4">
          <button
            className="py-3 w-[48%] bg-border rounded-full text-text font-bold text-800" onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 w-[48%] py-2 bg-blue-600 text-white rounded-full"
            onClick={onInstall}
          >
            Install
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstallModal;
