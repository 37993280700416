import React, { useState, useEffect, Suspense, lazy } from 'react';
import CacheClearer from './CacheClearer';
import CacheBuster from 'react-cache-buster';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import LayoutWrapper from './utils/LayoutWrapper';
import '@mantine/core/styles.css';
import packageInfo from '../package.json';
import { NewsProvider } from './context/newsProvide';
import { ThemeProvider, useTheme } from './context/themeProvider';
import branch from 'branch-sdk';
import { useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MixpanelEvent, sendPostHogEvent } from './utils/mixpanelUtil';
import { AppRatingProvider } from './context/AppRatingProvider';
import ErrorBoundary from './utils/ErrorBoundary';
import { LoginHomeLogo } from './assets/images';
import { LoadingSpinnerScreen } from './molecules/AppLoader';

// Lazy load all components
const HomePage = lazy(() => import('./components/HomePage'));
const AccountDetailsPage = lazy(() => import('./components/AccountDetails'));
const LoginPage = lazy(() => import('./components/Login'));
const SignupPage = lazy(() => import('./components/SignUp'));
const OnboardingPage = lazy(() => import('./components/Onboarding'));
const DuelPage = lazy(() => import('./components/Duel'));
const DuelState = lazy(() => import('./components/Duel/DuelState'));
const ChatPage = lazy(() => import('./components/Chat'));
const PyqPage = lazy(() => import('./components/Pyqs'));
const LatestNews = lazy(() => import('./components/News/latestNews'));
const ProfilePage = lazy(() => import('./components/Profile'));
const NewsDetailPage = lazy(() => import('./components/News/newsDetailPage'));
const NewsPyqPage = lazy(() => import('./components/News/newsPyqPage'));
const AccountDetailsForm = lazy(() => import('./components/SignUp/AccountDetailsForm'));
const ChatScreen = lazy(() => import('./components/Chat/TutorChat/ChatScreen'));
const EditProfile = lazy(() => import('./components/Profile/EditProfile'));
const PyqSearch = lazy(() => import('./components/Pyqs/PyqSearch'));
const MatchGame = lazy(() => import('./components/Duel/MatchGame/MatchGame'));
const Matching = lazy(() => import('./components/Duel/MatchGame/Matching'));
const DuelStart = lazy(() => import('./components/Duel/MatchGame/DuelStart'));
const DuelResults = lazy(() => import('./components/Duel/MatchGame/DuelResults'));
const DuelReview = lazy(() => import('./components/Duel/DuelReview'));
const NewsMcqPage = lazy(() => import('./components/News/newsMCQpage'));
const McqDiscussAi = lazy(() => import('./components/Chat/TutorChat/McqDiscussAI'));
const SelectBook = lazy(() => import('./components/Chat/AskABook/SelectBook'));
const BookChat = lazy(() => import('./components/Chat/AskABook/BookChat'));
const DuelFriendsList = lazy(() => import('./components/Duel/DuelFriendsList'));
const CustomizePractice = lazy(() => import('./components/Practice/CustomizePractice'));
const PracticeQuestions = lazy(() => import('./components/Practice/PracticeQuestions'));
const TabsComp = lazy(() => import('./components/Practice/TabsComp'));
const NewTest = lazy(() => import('./components/Practice/MockTest/NewTest'));
const OngoingTest = lazy(() => import('./components/Practice/MockTest/OngoingTest'));
const TestResult = lazy(() => import('./components/Practice/MockTestResults'));
const ReviewAnswer = lazy(() => import('./components/Practice/MockTestResults/ReviewAnswere'));
const ChangeTheme = lazy(() => import('./molecules/ChangeTheme'));
const MockTestInsights = lazy(() => import('./components/Practice/MockTestInsights'));
// const SparkTestResults = lazy(() => import('./components/SparkTest'));
// const SparkModal = lazy(() => import('./components/SparkTest/SparkTestModal'));
// const TestDetails = lazy(() => import('./components/TestDetails'));

const queryClient = new QueryClient();

// Create a screen tracker component
const ScreenTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Get the current pathname as screen name
    const screenName = location.pathname.substring(1) || 'home';
    const formattedScreenName = screenName.replace(/\//g, '-').replace(/^-/, '');

    // Start tracking when the route changes
    const startTime = new Date().getTime();

    // Define any extra parameters you want to track
    const extraParams = {
      path: location.pathname,
      referrer: document.referrer,
      // Add any state params if relevant
      ...(location.state || {}),
    };

    console.log(`Started tracking screen: ${formattedScreenName}`);

    // Track when component unmounts or route changes
    return () => {
      const endTime = new Date().getTime();
      const elapsedTime = endTime - startTime;

      const formatTime = (ms) => {
        const seconds = Math.floor(ms / 1000);
        return `${seconds} seconds`;
      };

      // Track the event
      MixpanelEvent(`Screen View - ${formattedScreenName}`, {
        screenTime: formatTime(elapsedTime),
        ...extraParams,
      });

      // If you're using PostHog as well
      sendPostHogEvent(`Screen View - ${formattedScreenName}`, {
        screenTime: formatTime(elapsedTime),
        ...extraParams,
      });

      console.log(`Tracked screen: ${formattedScreenName}, duration: ${formatTime(elapsedTime)}`);
    };
  }, [location]);

  return null;
};

const AppContent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { colorScheme } = useTheme();
  const navigate = useNavigate();
  const [deepLinkProcessed, setDeepLinkProcessed] = useState(false);
  const isSignupNewUser = localStorage.getItem('is_signup_newUser') === 'true';
  const userData = localStorage.getItem('userData');
  const location = useLocation();

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUserData = JSON.parse(localStorage.getItem('userData'));
      const userProfile = await fetch(`${process.env.REACT_APP_BASE_URL}/api/profile/${currentUserData.user}/`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!userProfile.ok) {
        throw new Error(`HTTP error! status: ${userProfile.status}`);
      }

      const userProfileData = await userProfile.json();
      if (userProfileData.roll_no) {
        localStorage.setItem('userData', JSON.stringify({ ...currentUserData, roll_no: userProfileData.roll_no }));
      }
    };

    if (userData) {
      console.log('userData', { userData });
      if (!userData.roll_nos) {
        fetchUserData();
      }
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData]);
  // comment
  useEffect(() => {
    try {
      branch.data((err, data) => {
        console.log('branch data', data);

        if (err) {
          console.error('Error getting deep link data:', err);
          if (!sessionStorage.getItem('hasReloaded')) {
            sessionStorage.setItem('hasReloaded', 'true');
            window.location.reload();
          }
          return;
        }

        if (data === null || data === undefined) {
          window.location.reload();
          return;
        }

        const linkData = data.data_parsed;
        if (!deepLinkProcessed && linkData) {
          let path = '';
          let state = {};

          switch (linkData.navigate_to) {
            case 'news/id':
              path = `news/${linkData.data.id}`;
              state = {
                newsItem: linkData.data,
                newsType: linkData.newsType,
              };
              break;

            case 'news/':
              path = '/news';
              state = {};
              break;

            case '/duel-friends':
              path = '/duel-friends';
              state = {
                inviterId: linkData.inviter_id,
                inviterName: linkData.inviter_name,
                isInvite: true,
              };
              break;

            default:
              return;
          }

          if (isLoggedIn) {
            navigate(path, { state });
          } else {
            localStorage.setItem('intendedDestination', JSON.stringify({ path, state }));
            localStorage.setItem('branchData', JSON.stringify(linkData));
            navigate('/login');
          }
          setDeepLinkProcessed(true);

          // Track deep link install
          const trackDeepLinkInstallFn = async (url, parsedData, source) => {
            try {
              const isFirstOpen = localStorage.getItem('@first_open');

              if (isFirstOpen === null) {
                localStorage.setItem('@first_open', 'done');
                let eventProps = {
                  source: source || 'branch',
                  link: url,
                  property: 'user opened the app via deep link',
                  linkdata: parsedData,
                };

                MixpanelEvent('Install via Deep Link_PLG', eventProps);
                sendPostHogEvent('Install via Deep Link_PLG', eventProps);
              } else {
                if (!url.includes('add_friend')) {
                  MixpanelEvent('User Audience Link Clicked_PLG', {
                    source: source || 'branch',
                    link: url,
                    property: 'user opened the app via deep link',
                    linkdata: parsedData,
                  });
                  sendPostHogEvent('User Audience Link Clicked_PLG', {
                    source: source || 'branch',
                    link: url,
                    property: 'user opened the app via deep link',
                    linkdata: parsedData,
                  });
                }
              }
            } catch (error) {
              console.error('Error tracking deep link install:', error);
            }
          };

          trackDeepLinkInstallFn(path, linkData, 'branch');
        }
      });
    } catch (error) {
      console.error('Error getting deep link data in useEffect:', error);
      window.location.reload();
    }
  }, [navigate, deepLinkProcessed, isLoggedIn]);

  useEffect(() => {
    if (isSignupNewUser) {
      navigate('/signupdetails');

      // Track signup via deeplink
      const branchData = localStorage.getItem('branchData');
      if (branchData) {
        const deepLinkData = JSON.parse(branchData);
        const userData = JSON.parse(localStorage.getItem('userData')) || {};

        MixpanelEvent('Signup via Deeplink', {
          deepLinkData: deepLinkData,
          source: deepLinkData.type || 'branch',
          user_id: userData.user,
          name: userData.name,
          phone: userData.phone,
        });

        sendPostHogEvent('Signup via Deeplink', {
          deepLinkData: deepLinkData,
          source: deepLinkData.type || 'branch',
          user_id: userData.user,
          name: userData.name,
          phone: userData.phone,
        });

        localStorage.removeItem('branchData');
      }
    } else if (isLoggedIn) {
      const intendedDestination = localStorage.getItem('intendedDestination');
      if (intendedDestination) {
        const { path, state } = JSON.parse(intendedDestination);
        navigate(path, { state });
        setTimeout(() => {
          localStorage.removeItem('intendedDestination');
        }, 2000);
      }
    }
  }, [isLoggedIn, isSignupNewUser, navigate]);

  useEffect(() => {
    if (isSignupNewUser) {
      navigate('/signupdetails');
    } else if (isLoggedIn) {
      const intendedDestination = localStorage.getItem('intendedDestination');
      if (intendedDestination) {
        const { path, state } = JSON.parse(intendedDestination);
        navigate(path, { state });
        setTimeout(() => {
          localStorage.removeItem('intendedDestination');
        }, 2000);
      }
    }
  }, [isLoggedIn, isSignupNewUser, navigate]);

  const PrivateRoute = ({ element, isLoggedIn, redirectPath = '/login' }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      const storedState = sessionStorage.getItem('navState');

      if (storedState) {
        try {
          const parsedState = JSON.parse(storedState);

          // Clear the state after reading
          sessionStorage.removeItem('navState');

          // Navigate with the state to the current route
          navigate(location.pathname, { state: parsedState, replace: true });
        } catch (error) {
          console.error('Error parsing navState:', error);
        }
      }
    }, [navigate, location.pathname]);

    // Guard logic
    if (!isLoggedIn) {
      return <Navigate to={redirectPath} state={{ from: location.pathname }} replace />;
    }

    return element;
  };

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={{ colorScheme }}>
      <CacheClearer />
      <LayoutWrapper>
        {/* {location.pathname !== '/signupdetails' && <SparkModal />} */}
        <ScreenTracker />
        <Suspense fallback={<LoadingSpinnerScreen />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={isLoggedIn ? <Navigate to={location.state?.from || '/news'} replace /> : <LoginPage />} />
            <Route path="/profile" element={<PrivateRoute element={<ProfilePage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/edit-profile" element={<PrivateRoute element={<EditProfile />} isLoggedIn={isLoggedIn} />} />
            <Route path="/signup" element={isLoggedIn ? <Navigate to={location.state?.from || '/news'} replace /> : <SignupPage />} />
            <Route
              path="/signupdetails"
              element={isLoggedIn && !isSignupNewUser ? <Navigate to={location.state?.from || '/news'} replace /> : <AccountDetailsForm />}
            />
            <Route path="/accountDetails" element={<PrivateRoute element={<AccountDetailsPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/onboarding" element={isLoggedIn ? <Navigate to={location.state?.from || '/news'} replace /> : <OnboardingPage />} />
            {/* Rest of the routes remain unchanged */}
            <Route path="/news" element={<PrivateRoute element={<LatestNews />} isLoggedIn={isLoggedIn} />} />
            <Route path="/news/:id" element={<PrivateRoute element={<NewsDetailPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/news/pyq/:id" element={<PrivateRoute element={<NewsPyqPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/news/mcq/:id" element={<PrivateRoute element={<NewsMcqPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/duel" element={<PrivateRoute element={<DuelPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/duelState" element={<DuelState />} />
            <Route path="/chat" element={<PrivateRoute element={<ChatPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/chat/mcq/" element={<McqDiscussAi />} />
            <Route path="/chat/new/" element={<PrivateRoute element={<ChatScreen />} isLoggedIn={isLoggedIn} />} />
            <Route path="/chat/:id" element={<ChatScreen />} />
            <Route path="/pyq" element={<PrivateRoute element={<PyqPage />} isLoggedIn={isLoggedIn} />} />
            <Route path="/pyqSearch" element={<PrivateRoute element={<PyqSearch />} isLoggedIn={isLoggedIn} />} />
            <Route path="/practice" element={<PrivateRoute element={<TabsComp />} isLoggedIn={isLoggedIn} />} />
            <Route path="/online-match-game" element={<PrivateRoute element={<MatchGame />} isLoggedIn={isLoggedIn} />} />
            <Route path="/matching" element={<Matching />} />
            <Route path="/duel-start" element={<DuelStart />} />
            <Route path="/duel-results" element={<DuelResults />} />
            <Route path="/duel-review" element={<DuelReview />} />
            <Route path="/book-chat/select-book" element={<PrivateRoute element={<SelectBook />} isLoggedIn={isLoggedIn} />} />
            <Route path="/book/:id" element={<PrivateRoute element={<BookChat />} isLoggedIn={isLoggedIn} />} />
            <Route path="/duel-friends" element={<DuelFriendsList />} />
            <Route path="/customize-practice" element={<PrivateRoute element={<CustomizePractice />} isLoggedIn={isLoggedIn} />} />
            <Route path="/practice-questions" element={<PracticeQuestions />} />
            <Route path="/review" element={<DuelReview />} />
            <Route path="/new-test" element={<NewTest />} />
            <Route path="/mock-test" element={<OngoingTest />} />
            <Route path="/test-result" element={<TestResult />} />
            <Route path="/test-review" element={<ReviewAnswer />} />
            <Route path="/change-theme" element={<ChangeTheme />} />
            <Route path="/mock-test-insights" element={<MockTestInsights />} />
            {/* <Route path="/spark-test-results" element={<SparkTestResults />} />
            <Route path="/spark-test-details" element={<TestDetails />} /> */}
          </Routes>
        </Suspense>
      </LayoutWrapper>
    </MantineProvider>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CacheBuster currentVersion={packageInfo.version} isEnabled={true} isVerboseMode={false}>
        <ThemeProvider>
          <NewsProvider>
            <AppRatingProvider>
              <ErrorBoundary>
                <Router>
                  <AppContent />
                </Router>
              </ErrorBoundary>
            </AppRatingProvider>
          </NewsProvider>
        </ThemeProvider>
      </CacheBuster>
    </QueryClientProvider>
  );
};

export default App;
