import React, { useState, useEffect } from 'react';
import { useTheme } from '../../context/themeProvider';
import Bookmarks from './bookmarks';
import MyNotes from './myNotes';
import { useDataStore } from '../../stores/dataStore';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-[60vh]">
    <div className="relative">
      <div className="w-12 h-12 border-4 border-blue-200 rounded-full"></div>
      <div className="h-12 w-12 border-t-2 border-b-2 border-[#6374FA] rounded-full animate-spin absolute top-0 left-0"></div>
    </div>
  </div>
);

const SavedTab = () => {
    const { colors, colorScheme } = useTheme();
    const windowHeight = window.innerHeight;
    const isResponsiveMobile = windowHeight <= 700;
    const [activeTab, setActiveTab] = useState('bookmarks');
    const isDarkTheme = colorScheme === 'dark';
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    
    // Get invalidate functions from dataStore
    const { 
      invalidateBookmarksCache, 
      invalidateHighlightsCache,
      setBookmarksCurrentPage,
      setHighlightsCurrentPage
    } = useDataStore();

    // Refresh both data sources when SavedTab component mounts
    useEffect(() => {
      // Invalidate both caches to force refresh
      invalidateBookmarksCache();
      invalidateHighlightsCache();
      
      // Reset pagination
      setBookmarksCurrentPage(1);
      setHighlightsCurrentPage(1);
      
      // Update refresh trigger to force child components to refresh
      setRefreshTrigger(prev => prev + 1);
    }, []);

    const handleTabChange = (tab) => {
      if (tab === activeTab) return;
      setActiveTab(tab);
    };

    return (
        <div
            className={`flex flex-col ${
                windowHeight > 900 ? 'h-screen' : isResponsiveMobile ? 'h-[33em]' : 'h-[45em]'
            }`}
            style={{ backgroundColor: colors.background, color: colors.text }}
        >
            <HeaderTab 
              activeTab={activeTab} 
              setActiveTab={handleTabChange} 
              isDarkTheme={isDarkTheme} 
            />
            {activeTab === 'bookmarks' ? 
              <Bookmarks LoadingSpinner={LoadingSpinner} refreshTrigger={refreshTrigger} /> : 
              <MyNotes LoadingSpinner={LoadingSpinner} refreshTrigger={refreshTrigger} />
            }
        </div>
    );
};

const HeaderTab = ({ activeTab, setActiveTab, isDarkTheme }) => {
    return (
        <div
            className={`flex gap-[10px] p-[8px] rounded-[25px] my-[0.8em] mx-[0.8em] ${isDarkTheme ? 'bg-[#303034]' : 'bg-[#E8E8E8]'}`}
        >
            <button
                className={`
                    py-[8px] px-[16px] w-[50%] h-[40px] rounded-[20px] cursor-pointer text-[14px] font-poppins border-none
                    ${
                        activeTab === 'bookmarks'
                            ? `${isDarkTheme ? 'bg-[#57575B] text-[#FFFFFF]' : 'bg-white text-[#070519] shadow-md'}`
                            : 'bg-transparent text-[#747474]'
                    }
                `}
                onClick={() => setActiveTab('bookmarks')}
            >
                Bookmarks
            </button>
            <button
                className={`
                    py-[8px] px-[16px] w-[50%] h-[40px] rounded-[20px] cursor-pointer text-[14px] font-poppins border-none
                    ${
                        activeTab === 'notes'
                            ? `${isDarkTheme ? 'bg-[#57575B] text-[#FFFFFF]' : 'bg-white text-[#070519] shadow-md'}`
                            : 'bg-transparent text-[#747474]'
                    }
                `}
                onClick={() => setActiveTab('notes')}
            >
                My notes
            </button>
        </div>
    );
};

export default SavedTab;