import React, { useEffect, useState } from 'react';
import { useTheme } from '../../context/themeProvider';
import { useNews } from '../../context/newsProvide';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { ArrowDown, ArrowDownDarKIcon } from '../../assets/images';

export function ReadingTime() {
  const { colorScheme } = useTheme();
  const { readingTime, setReadingTime } = useNews();
  const [isOpen, setIsOpen] = useState(false);

  const readingTimeOptions = [
    { value: '5', label: '5 min read' },
    { value: '10', label: '10 min read' },
    { value: '15', label: '15 min read' },
    { value: '30', label: '30 min read' },
    { value: '60', label: '60 min read' },
  ];

  const handleReadingTimeChange = (value) => {
    setReadingTime(value ? parseInt(value, 10) : null);
    MixpanelEvent('news_reading_time_clicked', { reading_time: `${value} min read` });
    sendPostHogEvent('news_reading_time_clicked', { reading_time: `${value} min read` });
    setIsOpen(false);
  };

  const handleClear = (event) => {
    event.stopPropagation();
    setReadingTime(null);
    MixpanelEvent('news_reading_time_cleared', { reading_time: 'cleared' });
    sendPostHogEvent('news_reading_time_cleared', { reading_time: 'cleared' });
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.dropdown-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative flex-1 w-40 h-12 z-10 dropdown-container items-center">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer ${
          !readingTime && 'text-disableTextColor'
        } text-sm flex h-12 justify-between py-1.5 px-4 rounded-lg bg-background border border-border items-center shadow-md`}
      >
        {readingTime ? `${readingTime} min read` : 'Reading Time'}
        {!readingTime && (
          <img
            src={colorScheme === 'dark' ? ArrowDownDarKIcon : ArrowDown}
            alt="Arrow down icon"
            className={`w-3.5 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
          />
        )}
        {readingTime && (
          <button onClick={handleClear} className="border-none bg-transparent cursor-pointer text-2xl text-md">
            &times;
          </button>
        )}
      </div>

      {isOpen && (
        <div
          className={`absolute left-0 mt-2 w-full bg-cardBackground border border-border rounded-lg shadow-lg z-20 transition-transform duration-300 transform ${
            isOpen ? 'translate-y-0' : '-translate-y-20'
          }`}
        >
          {readingTimeOptions.map((option) => (
            <div
              key={option.value}
              onClick={() => handleReadingTimeChange(option.value)}
              className={`cursor-pointer py-2 px-4 ${
                readingTime && readingTime === parseInt(option.value, 10) ? 'text-black bg-selectedOptionBackground' : ''
              }`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}