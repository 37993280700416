import React, { useEffect, useState } from 'react';
import { NoBookmarks } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { useDataStore } from '../../stores/dataStore';
import { useTheme } from '../../context/themeProvider';
import { callGETAPI } from '../../utils/NetworkUtil';
import NewsListItem from './newsCard';
import { useNavigate } from 'react-router-dom';
import Pagination from './pagination'; 

export default function Bookmarks({ refreshTrigger }) {
  const userData = userStore((state) => state.userData);
  const { colors } = useTheme();
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const navigate = useNavigate();
  const [contentHeight, setContentHeight] = useState(window.innerHeight);
  
  // Use the dataStore instead of local state
  const {
    bookmarks, 
    bookmarksLoading, 
    bookmarksTotalPages,
    bookmarksCurrentPage,
    bookmarksCache,
    setBookmarks,
    setBookmarksLoading,
    setBookmarksTotalPages,
    setBookmarksCurrentPage,
    cacheBookmarksPage,
    invalidateBookmarksCache
  } = useDataStore();

  const fetchBookmarks = (page) => {
    if (!userData?.user_id) return;
    
    // Check if we already have this page cached
    if (bookmarksCache[page]) {
      setBookmarks(bookmarksCache[page]);
      setBookmarksLoading(false);
      return;
    }
    
    // If not cached, fetch from API
    setBookmarksLoading(true);
    callGETAPI(
      `${process.env.REACT_APP_BASE_URL}/api/user-bookmarks/?user=${userData.user_id}&page=${page}&page_size=50`,
      (response) => {
        if (response.data?.results) {
          setBookmarks(response.data.results);
          cacheBookmarksPage(page, response.data.results); // Cache the results
          setBookmarksTotalPages(Math.ceil(response.data.count / 50));
        }
        setBookmarksLoading(false);
      },
      (error) => {
        console.error('API call error:', error);
        setBookmarksLoading(false);
      }
    );
  };

  useEffect(() => {
    // Invalidate cache and fetch fresh data when refreshTrigger changes
    invalidateBookmarksCache();
    fetchBookmarks(bookmarksCurrentPage);
  }, [refreshTrigger]);

  useEffect(() => {
    // This will fetch bookmarks when the component mounts
    // or when bookmarksCurrentPage changes
    fetchBookmarks(bookmarksCurrentPage);
    
    // Add event listener for when app regains focus
    const handleFocus = () => {
      invalidateBookmarksCache();
      fetchBookmarks(bookmarksCurrentPage);
    };
    
    window.addEventListener('focus', handleFocus);
    
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [bookmarksCurrentPage, userData?.user_id]); // Add dependencies

  useEffect(() => {
    const handleResize = () => {
      const adjustedHeight = window.innerHeight;
      setContentHeight(adjustedHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNewsClick = (data, index) => {
    navigate(`/news/${data.id}`, {
      state: {
        newsItem: {
          id: data.id,
        },
      },
    });
  };

  const handlePageChange = (page) => {
    setBookmarksCurrentPage(page);
  };

  if (bookmarksLoading && bookmarks.length === 0) {
    return <NoBookmarkComp colors={colors} />;
  }

  if (!bookmarksLoading && bookmarks.length === 0) {
    return <NoBookmarkComp colors={colors} />;
  }

  return (
    <div className="flex flex-col">
      <div
        className="flex-grow p-4 overflow-y-scroll scrollbar-hidden"
        style={{
          height: `${contentHeight - 336}px`, 
        }}
      >
      {bookmarks.map((data, index) => (
          <NewsListItem
            key={data.id}
            tags={data?.gpt_output?.mains_syllabus || []}
            title={data.title}
            likes={data.likes}
            bookmarks={data.bookmarks}
            imageSrc={data.imageSrc}
            newsItem={data}
            newsType={selectedNews}
            created_at={data.created_at}
            onClick={() => handleNewsClick(data, index)}
          />
        ))}
      </div>

      {/* Pagination Section */}
      <div className="flex-shrink-0 bg-background flex justify-center border-t border-border">
        <Pagination
          totalPages={bookmarksTotalPages}
          currentPage={bookmarksCurrentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}

const NoBookmarkComp = () => {
  return (
    <div className="h-[72vh] bg-background overflow-scroll">
      <div className="flex justify-center items-center h-full">
        <div className="w-[80%] max-w-[600px] text-center">
          <img
            src={NoBookmarks}
            alt="nothing here img"
            className="w-full object-cover mb-4"
          />
          <h2 className="font-normal font-poppins mb-2">Nothing here!</h2>
          <p className="font-poppins text-sm mx-8 leading-7 text-gray-500">
            Bookmark your favorite News Summaries for a personalized News Feed.
          </p>
        </div>
      </div>
    </div>
  );
};