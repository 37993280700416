import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text, Space } from '@mantine/core';
import {
  ArrowLeft,
  DuelStateIcon,
  Edit2,
  newsFeedInfo,
  ProfilePic,
  DownloadSvg,
  Bell,
  PwaInfo1,
  PwaInfo2,
  AnnouncementIcon,
} from '../../assets/images';
import { routeTitles } from '../../constants/headerTitle';
import { useTheme } from '../../context/themeProvider';
import useInstallPromptStore from '../../stores/eventListenerStore';
import {
  initMixpanel,
  identifyUser,
  setMixpanelUserProperties,
  MixpanelEvent,
  initPostHog,
  sendPostHogEvent,
  setPostHogUserProperties,
} from '../../utils/mixpanelUtil'; // Import named exports
import moengage from '@moengage/web-sdk';
import { userStore } from '../../stores/userStore';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useModalStore from '../../stores/userModalStote'; // Ensure this path is correct
import SafariModal from '../SafariModal';
import FireFoxModal from '../FireFoxModal';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { colorScheme } = useTheme();
  const [profilePic, setProfilePic] = useState(ProfilePic);
  const [visitedNewsFeed, setVisitedNewsFeed] = useState(false);
  const deferredPrompt = useInstallPromptStore((state) => state.deferredPrompt);
  const isPromptFired = useInstallPromptStore((state) => state.isPromptFired);
  const clearDeferredPrompt = useInstallPromptStore((state) => state.clearDeferredPrompt);
  const userData = userStore((state) => state.userData);
  const userId = userData?.user;
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [showSafariModal, setShowSafariModal] = useState(false);
  const [showFirefoxModal, setShowFirefoxModal] = useState(false);
  const { isDismissed, setShown } = useModalStore(); // Access the modal store

  useEffect(() => {
    const handleProfileUpdate = () => {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && userData.photo) {
        setProfilePic(userData.photo);
      }
    };
    window.addEventListener('profileUpdated', handleProfileUpdate);
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData && userData.photo) {
      setProfilePic(userData.photo);
    }
    return () => {
      window.removeEventListener('profileUpdated', handleProfileUpdate);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/news') {
      setVisitedNewsFeed(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const promptFired = window.sessionStorage.getItem('deferredPromptFired');
    if (isPromptFired || promptFired) {
      setShowInstallButton(true);
    }
  }, [isPromptFired]);

  useEffect(() => {
    moengage.initialize({ app_id: '7DJH2RCLMVG8GU54LCGKV2R0', cluster: 'DC_3', debug_logs: 0, swPath: '/service-worker.js' });

    // Initialize Mixpanel and set user properties
    identifyUser(userId);
    // setPostHogUserProperties({
    //   id: userId,
    // });
    setMixpanelUserProperties({
      email: userData.email,
      name: userData.name,
      phone: userData.phone,
    });

    moengage.add_unique_user_id(userId);
    moengage.add_mobile(userData.phone);
    moengage.add_first_name(userData.name);

    var moeData = localStorage.getItem('MOE_DATA');
    moeData = JSON.parse(moeData);
    console.log('moeData', moeData);

    const oneWeekInMilliseconds = 604800000; // 7 * 24 * 60 * 60 * 1000
    const currentTime = Date.now();

    if (moeData) {
      const optInShownTime = moeData.OPT_IN_SHOWN_TIME;
      const softAskStatus = moeData?.SOFT_ASK_STATUS || 'not_shown';

      if (softAskStatus === 'not_shown' || (softAskStatus === 'dismissed' && currentTime - optInShownTime > oneWeekInMilliseconds)) {
        moengage.call_web_push({
          soft_ask: true,
          main_class: 'moe-main-class',
          allow_class: 'moe-allow-class',
          block_class: 'moe-block-class',
        });
      }
    } else {
      // If MOE_DATA is not available, you might want to handle this case
      console.warn('MOE_DATA not found in localStorage');
    }
  }, []);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const promptFired = window.sessionStorage.getItem('deferredPromptFired');
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (!isStandalone) {
      if (isChrome) {
        if (isPromptFired || promptFired) {
          setShowInstallButton(true);
        }
      } else if (isSafari || isFirefox) {
        setShowInstallButton(true);
      }
    }
  }, [isPromptFired]);

  const handleInstallClick = () => {
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

    if (deferredPrompt && isChrome) {
      MixpanelEvent('pwa_install_clicked', {
        source: 'header',
        browser: 'chrome',
      });
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        clearDeferredPrompt();
        window.sessionStorage.removeItem('deferredPromptFired');
      });
    } else if (isSafari) {
      setShowSafariModal(true);
      MixpanelEvent('pwa_install_clicked', {
        source: 'header',
        browser: 'safari',
      });
    } else if (isFirefox) {
      setShowFirefoxModal(true);
      MixpanelEvent('pwa_install_clicked', {
        source: 'header',
        browser: 'firefox',
      });
    }
  };
  let title = 'Page';
  if (location.pathname.startsWith('/chat/')) {
    title = routeTitles['/chat/:id'] || 'New Chat';
  } else {
    title = routeTitles[location.pathname] || 'Page';
  }

  const goToProfile = () => {
    MixpanelEvent('profile_opened', { source: window.location.pathname });
    sendPostHogEvent('profile_opened', { source: window.location.pathname });
    navigate('/profile');
  };

  const backClick = () => {
    navigate(-1);
  };

  const currentRoute = location.pathname;

  if (
    currentRoute === '/profile' ||
    currentRoute === '/chat/new' ||
    currentRoute === '/duelState' ||
    currentRoute.startsWith('/chat/') ||
    currentRoute === '/spark-test-results'
  ) {
    return null;
  }
  const handleImageClick = () => {
    MixpanelEvent('duel_stats_clicked', { source: 'graph icon' });
    sendPostHogEvent('duel_stats_clicked', { source: 'graph icon' });
    navigate('/duelState');
  };

  // const handleAnnouncementClick = () => {
  //   setShown(true);
  // };

  return (
    <>
      <div className="font-poppins absolute flex justify-between items-center w-full mx-auto h-[3.9em] px-3 bg-background text-text fixed top-0 z-[999] left-0 right-0 header-container">
        <div className="flex items-center">
          {title === 'New Chat' ? (
            <>
              <LazyLoadImage onClick={backClick} className="pl-4 cursor-pointer" src={ArrowLeft} alt="Arrow Left" effect="blur" />
              <Space w={'1em'} />
              <Text fw={500} fz={'1.2em'}>
                {title}
              </Text>
            </>
          ) : (
            <>
              <div className="font-poppins-semibold-18 ml-2 text-[1.2em]">{title}</div>

              {visitedNewsFeed && currentRoute === '/news' && (
                <div
                  className="tooltip-container relative inline-block"
                  onMouseEnter={(e) => {
                    const tooltip = e.currentTarget.querySelector('.tooltip');
                    tooltip.style.visibility = 'visible';
                    tooltip.style.opacity = 1;
                  }}
                  onMouseLeave={(e) => {
                    const tooltip = e.currentTarget.querySelector('.tooltip');
                    tooltip.style.visibility = 'hidden';
                    tooltip.style.opacity = 0;
                  }}
                >
                  <LazyLoadImage src={newsFeedInfo} alt="News Feed" className="ml-4 w-8 mt-1 h-8 cursor-pointer" effect="blur" />
                  <div
                    className="tooltip invisible opacity-0 w-[170px] rounded-lg p-4 absolute z-[99999] top-[125%] left-[10%] shadow-md text-sm transition-opacity duration-300"
                    style={{ backgroundColor: colorScheme === 'dark' ? 'white' : 'black', color: colorScheme === 'dark' ? '#070519' : 'white' }}
                  >
                    Your news feed is curated using AI to show you relevant stories.
                    <div
                      className="absolute bottom-full left-[10%] border-[10px] border-solid border-transparent"
                      style={{ borderBottomColor: colorScheme === 'dark' ? 'white' : 'black' }}
                    ></div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex items-center">
          {showInstallButton && (
            <div className="mt-1 mr-2 cursor-pointer" onClick={handleInstallClick}>
              <LazyLoadImage src={DownloadSvg} alt="Download icon" effect="blur" />
            </div>
          )}

          {currentRoute === '/duel' && (
            <LazyLoadImage src={DuelStateIcon} className="cursor-pointer" alt="Dual State Icon" onClick={handleImageClick} effect="blur" />
          )}
          {/* <div
              className="relative flex items-center justify-center p-1 border border-gray-300 rounded-lg ml-1 cursor-pointer"
              onClick={handleAnnouncementClick}
            >
              <img src={AnnouncementIcon} alt="Announcement" />

              {!isDismissed && (
                <div className="absolute -top-1 -right-1 bg-deleteButtonBackground rounded-full w-4 h-4 flex items-center justify-center border border-white">
                  <span className="text-white text-xs font-bold">1</span>
                </div>
              )}
            </div> */}
          {currentRoute === '/profile' ? (
            <LazyLoadImage className="pr-6 cursor-pointer" src={Edit2} alt="Edit" effect="blur" />
          ) : (
            <LazyLoadImage
              className="ml-1 cursor-pointer w-16 h-16 max-w-[32px] max-h-[32px] rounded-full overflow-hidden"
              src={profilePic}
              alt="Profile"
              onClick={goToProfile}
              effect="blur"
            />
          )}
        </div>
      </div>
      <div className="moe-main-class hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-[1000]">
        <div className="moe-chrome-style-notification w-[90%] top-[30%] relative h-auto m-auto max-w-[400px] p-6 bg-cardBackground rounded-xl text-center shadow-md">
          <div className="moe-notification-image-wrapper mb-6">
            <div className="w-16 h-16 rounded-full bg-[#FFF9C4] flex justify-center items-center mx-auto">
              <LazyLoadImage src={Bell} alt="bell" effect="blur" />
            </div>
          </div>
          <div className="moe-text-wrapper">
            <h2 className="font-bold text-lg m-0 text-text mb-2">This website would like to send you awesome updates and offers!</h2>
            <p className="text-base text-text mb-6">Notifications can be turned off anytime from browser settings.</p>
          </div>
          <button
            className="moe-btn-allow moe-allow-class w-full py-3 px-6 bg-text text-background border-none rounded-full cursor-pointer text-base font-bold mb-2"
            onClick={() => {
              MixpanelEvent('allow_notifications_clicked');
              sendPostHogEvent('allow_notifications_clicked');
            }}
          >
            Allow
          </button>
          <button className="moe-btn-cancel moe-block-class w-full py-3 px-6 bg-transparent text-text  cursor-pointer text-base">Cancel</button>
        </div>
      </div>
      {showSafariModal && <SafariModal setShowSafariModal={setShowSafariModal} />}
      {showFirefoxModal && <FireFoxModal setShowFirefoxModal={setShowFirefoxModal} />}
    </>
  );
};

export default Header;
