import { create } from 'zustand';
import { callGETAPI } from '../utils/NetworkUtil';
import { getNewsPaperParamForApi } from '../utils/NewsFeedUtils';

const useQuestionsStore = create((set, get) => ({
  isLoading: false,
  mcqQuestions: [],
  mainsQuestions: [],
  prelimsQuestions: [],
  cachedData: {},
  currentPositions: {
    mcqs: 0,
    mains: 0,
    prelims: 0
  },
  
  fetchQuestions: async (selectedNews, selectedDate, userData) => {
    // Create a cache key based on the request parameters
    const cacheKey = `${selectedNews}-${selectedDate}-${userData.user}`;
    const cachedData = get().cachedData;
    
    // Check if we already have data for this combination
    if (cachedData[cacheKey]) {
      // Use cached data without showing loader
      set({
        mcqQuestions: cachedData[cacheKey].mcqs || [],
        mainsQuestions: cachedData[cacheKey].mains || [],
        prelimsQuestions: cachedData[cacheKey].prelims || []
      });
      return;
    }
    
    // Only show loader for new data requests
    set({ isLoading: true });
    
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/collatedmcqs/?news_paper=${getNewsPaperParamForApi(
      selectedNews
    )}&date=${selectedDate}&user=${userData.user}`;
    
    callGETAPI(
      apiUrl,
      (resp) => {
        // Store in cache
        const newCachedData = {...cachedData};
        newCachedData[cacheKey] = {
          mcqs: resp.data.mcqs || [],
          mains: resp.data.pyqs.mains_questions || [],
          prelims: resp.data.pyqs.prelims_questions || []
        };
        
        set({
          mcqQuestions: resp.data.mcqs || [],
          mainsQuestions: resp.data.pyqs.mains_questions || [],
          prelimsQuestions: resp.data.pyqs.prelims_questions || [],
          cachedData: newCachedData,
          isLoading: false
        });
      },
      (err) => {
        console.error('API error:', err);
        set({
          isLoading: false
        });
      }
    );
  },
  
  // Save the current question index for a specific tab
  savePosition: (tab, index) => {
    set(state => ({
      currentPositions: {
        ...state.currentPositions,
        [tab]: index
      }
    }));
  },
  
  // Get the saved position for a tab
  getPosition: (tab) => {
    return get().currentPositions[tab];
  }
}));

export default useQuestionsStore;