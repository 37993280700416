import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { TimerDarkIcon, TimerlightIcon } from '../../assets/images';
import { useTheme } from '../../context/themeProvider';

export default function NoDataLatestNews() {
  const { colorScheme } = useTheme();

  return (
    <div className="w-full h-full flex flex-col justify-center items-center text-center">
      <div className={`relative`}>
        <LazyLoadImage src={colorScheme === 'dark' ? TimerDarkIcon : TimerlightIcon} alt="Timer Icon" effect="blur" />
      </div>
      <h3 className="m-0 mb-2.5 text-text text-xl font-medium">Summary on the way</h3>
      <p className="m-0 text-gray-600 text-sm leading-6">
        We're preparing this day's Summary.
        <br />
        Stay tuned for the latest News Feed!
      </p>
    </div>
  );
}
